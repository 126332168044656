import "./Profile.scss";
import Navbar from "./../../src/components/navbar/Navbar";
import ProfileForm from "../components/forms/ProfileForm";

export default function Profile() {
  return (
    <div className="container-flude">
      <Navbar></Navbar>
      <ProfileForm />
    </div>
  );
}
