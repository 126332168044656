import './imgSlider.scss';
import logo from './../../assets/logo/tnp-logo.png'; 

export default function ImgSlider(props) {
  return (
    <div className="loginImgContainer">
      <div className="login__Crossfade">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <img className="logo" src={logo} alt="TNP News Logo" />
      </div>
    </div>
  );
}
