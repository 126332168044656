import React, { useEffect, useMemo, useState } from "react";
import { Status } from "./../routes/Paths";
import {
  DateRangePicker,
  SelectPicker,
  Loader,
  Placeholder,
  Input,
  Table,
  Button,
  ButtonToolbar,
  DatePicker,
} from "rsuite";

import "./Audition.scss";

//icons
import Navbar from "./../../src/components/navbar/Navbar";
import previousIcon from "./../assets/icons/previous.svg";
import nextIcon from "./../assets/icons/next.svg";
import downloadIcon from "./../assets/icons/download.svg";
import calenderIcon from "./../assets/icons/calender.svg";
import calenderBlackIcon from "./../assets/icons/black-calender.svg";
import emailIcon from "./../assets/icons/email.svg";
import rupeeIcon from "./../assets/icons/rupee.svg";

import AuditionProfile from "../components/modal/AuditionProfile";

import {
  GetUsers,
  UpdateAuditionData,
} from "../logic/store/actions/actionConstants";
import Network from "../logic/api";
import { useDispatch, useSelector } from "react-redux";
import UpdateAuditionStatus from "../components/modal/UpdateAuditionStatus";
import UpdateAuditionDate from "../components/modal/UpdateAuditionDate";

const { Column, HeaderCell, Cell, ColumnGroup } = Table;

export default function Audition() {
  const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();
  const oneMonthAgoDate = new Date();
  oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 2);
  const oneMonthAgoTimestamp = oneMonthAgoDate.getTime();

  let baseFilter = {
    filter: "role-id-creation-time",
    role_id: 0,
    creation_ts_start: oneMonthAgoTimestamp,
    creation_ts_end: currentTimestamp,
  };

  const user = useSelector((state) => state.user);
  const statusData = Status.map((item) => ({
    label: item.status,
    value: item.id,
  }));
  const dispatch = useDispatch();

  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [audTotal, setAudTotal] = useState(0);
  const [audAccepted, setAudAccepted] = useState(0);
  const [audOnhold, setAudOnhold] = useState(0);
  const [audRejected, setAudRejected] = useState(0);
  const [searchText, setSearch] = useState("");
  const [activeStatus, setStatus] = useState(null);
  const [activeState, setState] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [activeDist, setDist] = useState([]);
  const [distData, setDistData] = useState([]);
  const [activeVillage, setVillage] = useState([]);
  const [villageData, setVillageData] = useState([]);
  const [activeDateRange, setDateRange] = useState([]);
  const [activeUser, setActiveUser] = useState({});
  const [open, setOpen] = useState(false);
  const [updateAuditionStatusOpen, setUpdateAuditionStatusOpen] =
    useState(false);
  const [updateAuditionDate, setUpdateAuditionDate] = useState(false);
  const [actionType, setActionType] = useState("");

  var auditionUsers = {
    total_auditionees: audTotal,
    selected_auditionees: audAccepted,
    rejected_auditionees: audRejected,
    onHold_auditionees: audOnhold,
    pending_autionees: audTotal - (audAccepted + audRejected + audOnhold),
    page_size: 15,
    page_no: 1,
  };

  // Add default value on page load
  useEffect(() => {
    getUsers(baseFilter);
    console.log("activeUser", activeUser);
    getLocation();
  }, []);

  async function getLocation() {
    try {
      const response = await fetch(
        "https://tnp-utils.s3.ap-south-1.amazonaws.com/location.json"
      );
      const locations = await response.json();
      let locData = [];
      let statesArray = [];
      let temp = [];

      locations.forEach((location) => {
        Object.keys(location).forEach((state, i) => {
          statesArray.push(state);
          location[state].forEach((distObj, j) => {
            Object.keys(distObj).forEach((dist, k) => {
              distObj[dist].forEach((pinObj) => {
                Object.keys(pinObj).forEach((pin) => {
                  pinObj[pin].forEach((village) => {
                    locData.push({
                      state: state,
                      dist: dist,
                      pin: pin,
                      village: village,
                    });
                  });
                });
              });
            });
          });
        });
      });
      let stateDataObj = [...new Set(locData.map((item) => item.state))].map(
        (item) => ({ label: item, value: item })
      );
      setLocations(locData);
      setStateData(stateDataObj);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    let filter = getFilter();
    if (!isEmpty(filter)) {
      getUsers(filter);
    }
  }, [
    searchText,
    activeStatus,
    activeState,
    activeDateRange,
    activeDist,
    activeVillage,
    stateData,
    locations,
    distData,
    villageData,
  ]);

  // Function to get filtered list
  function getFilter() {
    let data = {};
    if (searchText != "" && searchText.length != 0) {
      data = {
        filter: "name-role-id",
        role_id: 0,
        name:
          searchText.charAt(0).toUpperCase() +
          searchText.slice(1).toLowerCase(),
      };
    } else if (activeStatus != null) {
      data = {
        filter: "role-id-audition-status",
        role_id: 0,
        aud_status: activeStatus,
      };
    } else if (activeDateRange && activeDateRange.length != 0) {
      var fromDate = new Date(activeDateRange[0]);
      var toDate = new Date(activeDateRange[1]);
      data = {
        filter: "aud-status-aud-schedule",
        aud_status: 4,
        role_id: 0,
        aud_schedule_start: fromDate.getTime(),
        aud_schedule_end: toDate.getTime(),
      };
    } else if (
      activeState.length != 0 &&
      activeDist.length != 0 &&
      activeVillage.length != 0
    ) {
      data = {
        role_id: 0,
        filter: "state-district-block",
        state: activeState,
        district: activeDist,
        block: activeVillage,
      };
    } else if (activeState.length != 0 && activeDist.length != 0) {
      data = {
        role_id: 0,
        filter: "state-district",
        state: activeState,
        district: activeDist,
      };
    } else if (activeState.length != 0) {
      data = {
        role_id: 0,
        filter: "state",
        state: activeState,
      };
    } else {
      data = baseFilter;
    }

    return data;
  }

  function getUsers(data) {
    setLoading(true);
    dispatch(
      Network.networkCall({
        action: GetUsers,
        data: data,
      })
    ).then(function (result) {
      if (result.success) {
        setLoading(false);
        console.log("Successfully retrieved users data.", result.data.users);
        setUserList(result.data.resp.users);
        setAudTotal(result.data.resp.counts.total);
        setAudOnhold(result.data.resp.counts.onhold);
        setAudRejected(result.data.resp.counts.rejected);
        setAudAccepted(result.data.resp.counts.accepted);
      } else {
        setLoading(false);
        console.log("Failed to retrieved users data.", result);
      }
    });
  }

  const handleSearchChange = (searchText) => setSearch(searchText);
  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleStateChange = (state) => {
    if (state) {
      let data = locations.filter((l) => state.includes(l.state));
      let distData = [...new Set(data.map((item) => item.dist))].map(
        (item) => ({
          label: item,
          value: item,
        })
      );
      setState(state);
      setDistData(distData);
    } else {
      setState("");
      setDist("");
      setVillage("");
    }
  };

  const handleDistChange = (dist) => {
    if (dist) {
      let data = locations
        .filter((l) => activeState.includes(l.state))
        .filter((d) => dist.includes(d.dist));
      let villageData = [...new Set(data.map((item) => item.village))].map(
        (item) => ({ label: item, value: item })
      );
      setDist(dist);
      setVillageData(villageData);
    } else {
      setDist("");
      setVillage("");
    }
  };

  const handleVillageChange = (village) => {
    if (village) {
      setVillage(village);
    } else {
      setVillage("");
    }
  };

  const handleDateRangeChange = (dateRange) => setDateRange(dateRange);
  const handleOpen = (user) => {
    setActiveUser(user);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleAuditionStatusOpen = (actionType, user) => {
    setActionType(actionType);
    setActiveUser(user);
    setUpdateAuditionStatusOpen(true);
  };

  const handleAuditionStatusClose = () => {
    setUpdateAuditionStatusOpen(false);
  };

  const submitFeedback = (feedback, audStatus) => {
    console.log(
      "feedback :",
      feedback,
      "audStatus",
      audStatus,
      "activeUser.aud_schedule :",
      activeUser.aud_schedule
    );

    if (audStatus != "") {
      setLoading(true);
      dispatch(
        Network.networkCall({
          action: UpdateAuditionData,
          data: {
            user_id: activeUser.user_id,
            creation_ts: activeUser.creation_ts,
            role_id: 0,
            privileges: "0",
            aud_schedule: activeUser.aud_schedule,
            payment_status: 1,
            aud_status: audStatus,
            aud_feedback: feedback,
            is_active: 0,
          },
        })
      ).then((result) => {
        if (result.success) {
          setLoading(false);
          getUsers(baseFilter);
          console.log("Successfully UpdateAuditionData .", result.data);
        } else {
          setLoading(false);
          console.log("Failed to UpdateAuditionData.", result);
        }
      });
    }

    setUpdateAuditionStatusOpen(false);
  };

  const paymentStatusText = (pstatus) => {
    switch (pstatus) {
      case 0:
        return "Failed";
      case 1:
        return "Received";
      case 2:
        return "In Processing";

      default:
        return "Pending";
    }
  };

  const auditionTimeFormat = (timestemp) => {
    const formatted = new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(timestemp);

    return formatted;
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleButtonClick = (index, user) => {
    setActiveUser(user);
    setSelectedDate(index);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setUpdateAuditionDate(true);
  };

  const handleAuditionDateClose = () => {
    setUpdateAuditionDate(false);
  };

  const submitAuditionDate = () => {
    setLoading(true);
    console.log("date = ", selectedDate);
    setUpdateAuditionDate(false);
    if (selectedDate != "") {
      setLoading(true);
      dispatch(
        Network.networkCall({
          action: UpdateAuditionData,
          data: {
            user_id: activeUser.user_id,
            creation_ts: activeUser.creation_ts,
            role_id: 0,
            privileges: "0",
            aud_schedule:  selectedDate.getTime(),
            payment_status: 1,
            aud_status: 4,
            aud_feedback: "-",
            is_active: 0,
          },
        })
      ).then((result) => {
        if (result.success) {
          setLoading(false);
          getUsers(baseFilter);
          console.log("Successfully UpdateAuditionData .", result);
        } else {
          setLoading(false);
          console.log("Failed to UpdateAuditionData.", result);
        }
      });
    }
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function handleCloseDatePicker() {
    setSelectedDate(null);
  }

  return (
    <div className="container-flude">
      <Navbar></Navbar>

      <div className="container mt-5 text-danger">
        <h4>Review Auditionees</h4>
        {/* Report Index Start */}
        <div className="row mt-3">
          <div className="col-md-2">
            <div className="card registered">
              <div className="index">{auditionUsers.total_auditionees}</div>
              <div className="StatusText">Registered</div>
            </div>
          </div>

          <div className="col-md-2">
            <div
              className="card pending"
              style={{ backgroundColor: "#fffdaf" }}
            >
              <div className="index">{auditionUsers.pending_autionees}</div>
              <div className="StatusText">Pending</div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="card accepted">
              <div className="index">{auditionUsers.selected_auditionees}</div>
              <div className="StatusText">Accepted</div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="card rejected">
              <div className="index">{auditionUsers.rejected_auditionees}</div>
              <div className="StatusText">Rejected</div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="card pending">
              <div className="index">{auditionUsers.onHold_auditionees}</div>
              <div className="StatusText">On Hold</div>
            </div>
          </div>
        </div>
        {/* Search Start */}
        <div className="row mb-3">
          <div className="col-3">
            <Input placeholder="Search by Name" onChange={handleSearchChange} />
          </div>
          <div className="col-3">
            <DateRangePicker
              placeholder={"Filter By Audition Dates"}
              onChange={handleDateRangeChange}
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-3">
            <SelectPicker
              data={statusData}
              placeholder="Audition Status"
              onChange={handleStatusChange}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <SelectPicker
              data={stateData}
              placeholder="Search by State"
              onChange={handleStateChange}
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-3">
            <SelectPicker
              data={distData}
              placeholder="Search By District"
              onChange={handleDistChange}
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-3">
            <SelectPicker
              data={villageData}
              placeholder="Search by Block"
              onChange={handleVillageChange}
              style={{ width: "100%" }}
            />
          </div>

          {/* <div className="col-3 text-end">
            <button className="btn btn-outline-info">
              <img src={downloadIcon} /> Download
            </button>
          </div> */}
        </div>
        {/* Display audition data */}
        {loading ? (
          <div style={{ display: "flex", backgroundColor: "black" }}>
            <Loader center size="md" content="Loading..." vertical />{" "}
          </div>
        ) : (
          <div className="row mt-3">
            <div className="col table-data">
              <Table
                autoHeight={true}
                rowHeight={60}
                headerHeight={40}
                data={userList}
                onRowClick={(rowData) => {
                  console.log(rowData);
                }}
              >
                <Column width={70} align="center">
                  <HeaderCell>#</HeaderCell>
                  <Cell>{(rowData, index) => <span>{index + 1}</span>}</Cell>
                </Column>

                <Column width={200}>
                  <HeaderCell>Name/Age/Gender</HeaderCell>
                  <Cell>
                    {(user) => (
                      <span
                        onClick={() => handleOpen(user)}
                        className="userName"
                      >
                        {user.name + " / " + user.gender}
                      </span>
                    )}
                  </Cell>
                </Column>

                <Column width={200}>
                  <HeaderCell>Location</HeaderCell>
                  <Cell>
                    {(user) => (
                      <span className="location">
                        {" "}
                        {user.district + ", " + user.state}
                      </span>
                    )}
                  </Cell>
                </Column>

                <Column width={225}>
                  <HeaderCell>Payment Status</HeaderCell>
                  <Cell>
                    {(user) => (
                      <span> {paymentStatusText(user.payment_status)}</span>
                    )}
                  </Cell>
                </Column>

                <Column width={300}>
                  <HeaderCell>Interview</HeaderCell>
                  <Cell>
                    {(user, index) => (
                      <div className="row" key={"Interview" + index}>
                        {user.aud_schedule && selectedDate != index  ? (
                          <>
                            <span className="schedule-interview">
                              {auditionTimeFormat(user.aud_schedule)}
                            </span>
                            {user.aud_status === 4 && (
                              <span
                                onClick={() => handleButtonClick(index, user)}
                                className="interview Reschedule"
                              >
                                (Reschedule)
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {user.payment_status === 1 ? (
                              <>
                                {selectedDate === index ? (
                                  <DatePicker
                                    style={{ width: "70%", zIndex: 4 }}
                                    format="dd-MM-yyyy HH:mm"
                                    editable={false}
                                    onChange={handleDateChange}
                                    onClose={handleCloseDatePicker}
                                  />
                                ) : (
                                  <span
                                    onClick={() =>
                                      handleButtonClick(index, user)
                                    }
                                    className="interview"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      style={{ marginRight: 12 }}
                                      src={calenderIcon}
                                    />
                                    {"Shedule"}
                                  </span>
                                )}
                              </>
                            ) : (
                              <span
                                className="schedule-interview"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{ marginRight: 12 }}
                                  src={calenderBlackIcon}
                                />
                                {"Not Allowed"}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </Cell>
                </Column>

                <Column width={300} fixed="right">
                  <HeaderCell>Action</HeaderCell>
                  <Cell>
                    {(users, index) => (
                      <div className="row" key={"Action" + index}>
                        {user.role_id === 1 ? (
                          <div className="row" key={"Action" + index}>
                            <span className="schedule-interview">
                              {" Not Allowed"}
                            </span>
                          </div>
                        ) : (
                          <>
                            {users.aud_status === 4 && users.aud_schedule ? (
                              <>
                                <div
                                  className="col-12"
                                  key={"AcceptRejectBtn" + index}
                                >
                                  <ButtonToolbar>
                                    <Button
                                      onClick={() =>
                                        handleAuditionStatusOpen(
                                          "Accept",
                                          users
                                        )
                                      }
                                      size="sm"
                                      appearance="ghost"
                                    >
                                      Accept
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleAuditionStatusOpen(
                                          "On Hold",
                                          users
                                        )
                                      }
                                      size="sm"
                                      appearance="ghost"
                                    >
                                      On Hold
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleAuditionStatusOpen(
                                          "Reject",
                                          users
                                        )
                                      }
                                      size="sm"
                                      appearance="default"
                                    >
                                      Reject
                                    </Button>
                                  </ButtonToolbar>
                                </div>
                              </>
                            ) : (
                              <>
                                {users.aud_status === 3 ? (
                                  <div
                                    style={{ textAlign: "center" }}
                                    key={"Action" + index}
                                  >
                                    <span
                                      className="actionBox"
                                      style={{ backgroundColor: "#fae7d7" }}
                                    >
                                      {"Rejected"}
                                    </span>
                                  </div>
                                ) : users.aud_status === 1 ? (
                                  <div
                                    style={{ textAlign: "center" }}
                                    key={"Action" + index}
                                  >
                                    <span
                                      className="actionBox"
                                      style={{ backgroundColor: "#d7fad9" }}
                                    >
                                      {"Accepted"}
                                    </span>
                                  </div>
                                ) : users.aud_status === 2 ? (
                                  <div
                                    style={{ textAlign: "center" }}
                                    key={"Action" + index}
                                  >
                                    <span
                                      className="actionBox"
                                      style={{ backgroundColor: "#f0f3f4" }}
                                    >
                                      {"On Hold"}
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className="col-12"
                                    key={"AcceptRejectBtn" + index}
                                  >
                                    <ButtonToolbar>
                                      <Button
                                        size="sm"
                                        appearance="ghost"
                                        disabled
                                      >
                                        Accept
                                      </Button>
                                      <Button
                                        size="sm"
                                        appearance="ghost"
                                        disabled
                                      >
                                        On Hold
                                      </Button>
                                      <Button
                                        size="sm"
                                        appearance="default"
                                        disabled
                                      >
                                        Reject
                                      </Button>
                                    </ButtonToolbar>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </Cell>
                </Column>
              </Table>
            </div>
          </div>
        )}
        {/* <div className="row  mb-5 mt-3">
            <div className="col-md-4">
              <span className="pagination-text">Show rows per page </span>
              <select className="page ml-2">
                <option value="10">10</option>
                <option value="20">20</option>
              </select>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="previous col-2">
                  <img src={previousIcon} />
                </div>
                <div className="current col-4 text-center pt-2 text-dark">
                  <strong>1-10 </strong> of <span>12</span>
                </div>
                <div className="next col-2">
                  <img src={nextIcon} />
                </div>
              </div>
            </div>
          </div> */}

        {/*Modal for profile details*/}
        <AuditionProfile
          modalClose={handleClose}
          open={open}
          user={activeUser}
        ></AuditionProfile>

        <UpdateAuditionStatus
          user={activeUser}
          open={updateAuditionStatusOpen}
          modalClose={handleAuditionStatusClose}
          submitReason={submitFeedback}
          actionType={actionType}
        ></UpdateAuditionStatus>
        <UpdateAuditionDate
          user={activeUser}
          open={updateAuditionDate}
          modalClose={handleAuditionDateClose}
          submit={submitAuditionDate}
          date={selectedDate}
        ></UpdateAuditionDate>
      </div>
    </div>
  );
}
