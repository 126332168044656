const errors = {
    CodeMismatchException: 'Invalid verification code provided, please try again.',
    ExpiredCodeException: 'Code provided has expired, please Resend the same using button below.',
    InvalidPasswordException: 'Wrong password, please check again.',
    CodeDeliveryFailureException: 'Is your Email Correct, we couldn\'t deliver a confirmation code.',
};

function authError(code) {
    return errors[code];
}

export default authError;