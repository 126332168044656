import './NewsDetail.scss';
import Navbar from './../../src/components/navbar/Navbar';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import {Button, ButtonToolbar } from 'rsuite';
import { NavLink } from 'react-router-dom';
import downloadIcon from './../assets/icons/download.svg';
export default function NewsDetail() {
    return (
        <>
            <div className='container-flude border-bottom pb-3'>
                <Navbar></Navbar>

                <div className="container mt-5">
                    <div className="row">
                        <div className="col-9 back-to-news">
                            <NavLink to="/news" ><ArowBackIcon /> </NavLink>
                            News Detail View
                        </div>
                        <div className="col-3 text-end">
                            <ButtonToolbar>
                                <Button className="mr-2" color="cyan" appearance="primary" >Approve</Button>
                                <Button className="mr-2" appearance="ghost" >Reject</Button>
                                <Button appearance="ghost" >Hold</Button>
                            </ButtonToolbar>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col">
                        <h4 className="text-dark pt-2">After Argentina won FIFA World Cup, Lionel Messi hugged me and said :Emi Martinez reveals emotional moment after Qatar final</h4>
                    </div>
                </div>

                <div className="row news-image mt-2">
                    <div className="col-2">
                        <img  src="https://admin.tnpnews.in/images/detail_img_64f867fa4fdae~sonia%20gandhi.jpg" className="rounded float-end" />
                       </div>
                    <div className="col-2">
                        <img src="https://admin.tnpnews.in/images/detail_img_64f867fa4fdae~sonia%20gandhi.jpg" className="rounded float-end" />
                    </div>
                    <div className="col-2">
                        <img src="https://admin.tnpnews.in/images/detail_img_64f867fa4fdae~sonia%20gandhi.jpg" className="rounded float-end" />
                    </div>
                    <div className="col-2">
                        <img src="https://admin.tnpnews.in/images/detail_img_64f867fa4fdae~sonia%20gandhi.jpg" className="rounded float-end" />
                    </div>
                    <div className="col-2">
                        <img src="https://admin.tnpnews.in/images/detail_img_64f867fa4fdae~sonia%20gandhi.jpg" className="rounded float-end" />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col text-center">
                        <Button className="mr-2" appearance="ghost" ><img src={downloadIcon } /> &nbsp; Download All</Button>
                    </div>
                </div>

                <div className="row mt-3 mb-5">
                    <div className="col">
                        <p>Argentina's World Cup-winning goalkeeper Emiliano Martinez met his die-hard fans in Kolkata on Tuesday.
                            The 29-year-old, who was named the Golden Glove winner at the 2022 World Cup in Qatar, is the first reigning World Cup
                            champion to visit the city, and fans could not get enough of him and, at one stage, jumped over barricades to get a glimpse
                            of their hero from close quarters. Fortunately, Martinez was whisked away by security by then, but that was not before the Argentine
                            said that he did find similarities between the city of joy and his home country. "It is the passion for football that I find similar.
                            I see that passion in young kids here,"" he said during the "Tahader Kotha"" chat show at Kolkata"s Milan Mela Ground.</p>

                        <p>Argentina's World Cup-winning goalkeeper Emiliano Martinez met his die-hard fans in Kolkata on Tuesday.
                            The 29-year-old, who was named the Golden Glove winner at the 2022 World Cup in Qatar, is the first reigning World Cup
                            champion to visit the city, and fans could not get enough of him and, at one stage, jumped over barricades to get a glimpse
                            of their hero from close quarters. Fortunately, Martinez was whisked away by security by then, but that was not before the Argentine
                            said that he did find similarities between the city of joy and his home country. "It is the passion for football that I find similar.
                            I see that passion in young kids here,"" he said during the "Tahader Kotha"" chat show at Kolkata"s Milan Mela Ground.</p>

                        <p>Argentina's World Cup-winning goalkeeper Emiliano Martinez met his die-hard fans in Kolkata on Tuesday.
                            The 29-year-old, who was named the Golden Glove winner at the 2022 World Cup in Qatar, is the first reigning World Cup
                            champion to visit the city, and fans could not get enough of him and, at one stage, jumped over barricades to get a glimpse
                            of their hero from close quarters. Fortunately, Martinez was whisked away by security by then, but that was not before the Argentine
                            said that he did find similarities between the city of joy and his home country. "It is the passion for football that I find similar.
                            I see that passion in young kids here,"" he said during the "Tahader Kotha"" chat show at Kolkata"s Milan Mela Ground.</p>
                    </div>
                </div>

            </div>
            
        </>
    );
}
