import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

var poolData = {
  UserPoolId: process.env.REACT_APP_UserPoolId,
	ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

async function sendToken(username) {
  var userData = {
    Username: username,
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);
  console.log(username, cognitoUser);
  return new Promise((resolve, reject) =>
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    })
  );
}

export default sendToken;
