export const Paths = {
  // pages
  Dashboard: { path: '/' },
  Meetings: { path: '/meetings' },
  Register: { path: '/register' },
  ForgetPassword: { path: '/forget-password' },
  ForgetPassword2: { path: '/forget-password2' },
  Audition: { path: '/audition' },
  Auditionee: { path: '/auditionee' },
  Admin: { path: '/admin' },
  Account: { path: '/accounts' },
  News: { path: '/news' }, 
  DayPlan: { path: '/day-plan' }, 
  NewsDetail: { path: '/news-detail/:id' },
  ExpenseDetail: { path: '/expense-detail/:id' },
  Profile: { path: '/profile' },
  RegisterFormPersonal: {path:'/register-personal' },
  ConfirmEmailAndPhone: {path:'/confirm/:email/:phone/:name/:creation_ts' },
  Login: { path: '/auth/login' },
  CreateProfile: { path: '/create-profile' },
  PrivacyPolicy: { path: '/privacy-policy' }
};
export const Status = [{"id":1,"status":"Approved"},
                       {"id":2,"status":"On Hold"},
                       {"id":3,"status":"Rejected"},
                      { "id": 4, "status": "Awaited" }];

export const Roles = [
    { "id": 1, "role": "Vendor" },
    { "id": 2, "role": "Grameen Reporter" },
    { "id": 3, "role": "Block Approver" },
    { "id": 4, "role": "District Approver" },
    { "id": 5, "role": "State Approver" },
    { "id": 6, "role": "HO Approver" },
    { "id": 7, "role": "Administrator" },
    { "id": 8, "role": "Audition Judge" }];

export const Levels = [
    { "id": 1, level: "Block" },
    { "id": 2, level: "District" },
    { "id": 3, level: "State" },
    { "id": 4, level: "HO" }
];

export const NewsStatus = [
    { "id": 0, level: "New" },
    { "id": 1, level: "Approved" },
    { "id": 2, level: "Rejected" },
    { "id": 3, level: "Hold" }
];

export const NewsData = [{
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "After Argentina won FIFA World Cup, Lionel Messi hugged me and said��: Emi Martinez",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 1,
    "state_approved": 1,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "After Argentina won FIFA World Cup, Lionel Messi hugged me and said��: Emi Martinez",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 3,
    "ho_approved": 1,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "After Argentina won FIFA World Cup, Lionel Messi hugged me and said��: Emi Martinez",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 2,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "After Argentina won FIFA World Cup, Lionel Messi hugged me and said��: Emi Martinez",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 1,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 1,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 3,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 1,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 3,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}, {
    "reporter_id": "p@g.com",
    "news_id": "xfdsf-343-dsfsd-4d2",
    "news_title": "Breaking NEws",
    "news_description": "Breaking News Detail",
    "news_image_links": ["l1", "l2"],
    "news_video_links": ["lx", "h1"],
    "expense_id": "dfdsf-343-dsfsd-42",
    "gps_coords": {
        "lat": 23.232,
        "lng": 44.232
    },
    "block": "South",
    "district": "East",
    "state": "Delhi",
    "reported_date": 1694763307952,
    "block_approved": 1,
    "district_approved": 3,
    "state_approved": 2,
    "ho_approved": 0,
    "block_approver_id": "a@b.com",
    "district_approver_id": "a@b.com",
    "state_approver_id": "a@b.com",
    "ho_approver_id": "a@b.com",
    "block_approver_name": "Prateek",
    "district_approver_name": "Reetesh",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Murari",
    "block_approved_date": 1694763307952,
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "block_arh_reason": "Approving",
    "district_arh_reason": "Approved Good Job",
    "state_arh_reason": "Fake",
    "ho_arh_reason": "No Evidence"
}];


export const ExpenseData = [{
    "expense_id": "sdfk-343-sdfd",
    "news_id": ["sdfsd-232-dfsdf", "sdfsd-232-dfsdf"],
    "news_title": ["After Argentina won FIFA World Cup, Lionel Messi hugged me and said :Emi Martinez reveals emotional moment after Qatar final", "After Argentina won FIFA World Cup, Lionel Messi hugged me and said :Emi Martinez reveals emotional moment after Qatar final"],
    "expense_links": ["l1", "l2"],
    "news_video_links": [["l1", "l2"], ["l1", "l2"]],
    "news_image_links": [["l1", "l2"], ["l1", "l2"], ["l1", "l2"]],
    "news_block": ["Agra","Mathura"],
    "news_district": ["Agra", "Mathura"],
    "news_state": ["Uttar Pradesh", "Uttar Pradesh"],
    "travel_expense": 900,
    "food_expense": 222,
    "hotel_expense": 123,
    "other_expense": 987,
    "expense_reported_date": 1694763307952,
    "expense_description": "Travel",
    "block_approved": 1,
    "district_approved": 2,
    "state_approved": 1,
    "ho_approved": 3,
    "district_approver_id": "a@g.com",
    "state_approver_id": "a@g.com",
    "ho_approver_id": "a@g.com",
    "district_approver_name": "Prateek",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Reetesh",
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "district_arh_reason": "Approved",
    "state_arh_reason": "Fake Bill",
    "ho_arh_reason": "Not approved"
}, {
    "expense_id": "sdfk-343-sdfd",
    "news_id": ["sdfsd-232-dfsdf", "sdfsd-232-dfsdf"],
    "news_title": ["Title", "Title 2"],
    "expense_links": ["l1", "l2"],
    "news_video_links": [["l1", "l2"], ["l1", "l2"]],
    "news_image_links": [["l1", "l2"], ["l1", "l2"]],
    "news_block": ["Agra", "Mathura"],
    "news_district": ["Agra", "Mathura"],
    "news_state": ["Uttar Pradesh", "Uttar Pradesh"],
    "travel_expense": 900,
    "food_expense": 222,
    "hotel_expense": 123,
    "other_expense": 987,
    "expense_reported_date": 1694763307952,
    "expense_description": "Travel",
    "block_approved": 3,
    "district_approved": 2,
    "state_approved": 1,
    "ho_approved": 3,
    "district_approver_id": "a@g.com",
    "state_approver_id": "a@g.com",
    "ho_approver_id": "a@g.com",
    "district_approver_name": "Prateek",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Reetesh",
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "district_arh_reason": "Approved",
    "state_arh_reason": "Fake Bill",
    "ho_arh_reason": "Not approved"
}, {
    "expense_id": "sdfk-343-sdfd",
    "news_id": ["sdfsd-232-dfsdf", "sdfsd-232-dfsdf"],
    "news_title": ["Title", "Title 2"],
    "expense_links": ["l1", "l2"],
    "news_video_links": [["l1", "l2"], ["l1", "l2"]],
    "news_image_links": [["l1", "l2"], ["l1", "l2"]],
    "news_block": ["Agra", "Mathura"],
    "news_district": ["Agra", "Mathura"],
    "news_state": ["Uttar Pradesh", "Uttar Pradesh"],
    "travel_expense": 900,
    "food_expense": 222,
    "hotel_expense": 123,
    "other_expense": 987,
    "expense_reported_date": 1694763307952,
    "expense_description": "Travel",
    "block_approved": 2,
    "district_approved": 2,
    "state_approved": 1,
    "ho_approved": 3,
    "district_approver_id": "a@g.com",
    "state_approver_id": "a@g.com",
    "ho_approver_id": "a@g.com",
    "district_approver_name": "Prateek",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Reetesh",
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "district_arh_reason": "Approved",
    "state_arh_reason": "Fake Bill",
    "ho_arh_reason": "Not approved"
}, {
    "expense_id": "sdfk-343-sdfd",
    "news_id": ["sdfsd-232-dfsdf", "sdfsd-232-dfsdf"],
    "news_title": ["Title", "Title 2"],
    "expense_links": ["l1", "l2"],
    "news_video_links": [["l1", "l2"], ["l1", "l2"]],
    "news_image_links": [["l1", "l2"], ["l1", "l2"]],
    "news_block": ["Agra", "Mathura"],
    "news_district": ["Agra", "Mathura"],
    "news_state": ["Uttar Pradesh", "Uttar Pradesh"],
    "travel_expense": 900,
    "food_expense": 222,
    "hotel_expense": 123,
    "other_expense": 987,
    "expense_reported_date": 1694763307952,
    "expense_description": "Travel",
    "block_approved": 1,
    "district_approved": 2,
    "state_approved": 1,
    "ho_approved": 3,
    "district_approver_id": "a@g.com",
    "state_approver_id": "a@g.com",
    "ho_approver_id": "a@g.com",
    "district_approver_name": "Prateek",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Reetesh",
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "district_arh_reason": "Approved",
    "state_arh_reason": "Fake Bill",
    "ho_arh_reason": "Not approved"
}, {
    "expense_id": "sdfk-343-sdfd",
    "news_id": ["sdfsd-232-dfsdf", "sdfsd-232-dfsdf"],
    "news_title": ["Title", "Title 2"],
    "expense_links": ["l1", "l2"],
    "news_video_links": [["l1", "l2"], ["l1", "l2"]],
    "news_image_links": [["l1", "l2"], ["l1", "l2"]],
    "news_block": ["Agra", "Mathura"],
    "news_district": ["Agra", "Mathura"],
    "news_state": ["Uttar Pradesh", "Uttar Pradesh"],
    "travel_expense": 900,
    "food_expense": 222,
    "hotel_expense": 123,
    "other_expense": 987,
    "expense_reported_date": 1694763307952,
    "expense_description": "Travel",
    "block_approved": 1,
    "district_approved": 2,
    "state_approved": 1,
    "ho_approved": 3,
    "district_approver_id": "a@g.com",
    "state_approver_id": "a@g.com",
    "ho_approver_id": "a@g.com",
    "district_approver_name": "Prateek",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Reetesh",
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "district_arh_reason": "Approved",
    "state_arh_reason": "Fake Bill",
    "ho_arh_reason": "Not approved"
}, {
    "expense_id": "sdfk-343-sdfd",
    "news_id": ["sdfsd-232-dfsdf", "sdfsd-232-dfsdf"],
    "news_title": ["Title", "Title 2"],
    "expense_links": ["l1", "l2"],
    "news_video_links": [["l1", "l2"], ["l1", "l2"]],
    "news_image_links": [["l1", "l2"], ["l1", "l2"]],
    "news_block": ["Agra", "Mathura"],
    "news_district": ["Agra", "Mathura"],
    "news_state": ["Uttar Pradesh", "Uttar Pradesh"],
    "travel_expense": 900,
    "food_expense": 222,
    "hotel_expense": 123,
    "other_expense": 987,
    "expense_reported_date": 1694763307952,
    "expense_description": "Travel",
    "block_approved": 1,
    "district_approved": 2,
    "state_approved": 1,
    "ho_approved": 3,
    "district_approver_id": "a@g.com",
    "state_approver_id": "a@g.com",
    "ho_approver_id": "a@g.com",
    "district_approver_name": "Prateek",
    "state_approver_name": "Krishna",
    "ho_approver_name": "Reetesh",
    "district_approved_date": 1694763307952,
    "state_approved_date": 1694763307952,
    "ho_approved_date": 1694763307952,
    "district_arh_reason": "Approved",
    "state_arh_reason": "Fake Bill",
    "ho_arh_reason": "Not approved"
}];

export const DayPlans = [
    {
        "user_id": "test@g.com",
        "date": "1694763307952",
        "description": "Planning to cover rally and travel.",
        "block_approved": 0,
        "district_approved": 1,
        "state_approved": 2,
        "ho_approved": 1,
        "block_approver_id": "a@b.com",
        "district_approver_id": "x@b.com",
        "state_approver_id": "c@b.com",
        "ho_approver_id": "d@b.com",
        "block_approver_name": "Prateek",
        "district_approver_name": "Krishna",
        "state_approver_name": "Aditya",
        "ho_approver_name": "Reetesh",
        "block_approved_date": "1694763307952",
        "district_approved_date": "1694763307952",
        "state_approved_date": "1694763307952",
        "ho_approved_date": "1694763307952",
        "block_arh_reason": "Prateek",
        "district_arh_reason": "Krishna",
        "state_arh_reason": "Aditya",
        "ho_arh_reason": "Reetesh"
    },
    {
        "user_id": "test@g.com",
        "date": "1694763307952",
        "description": "Planning to cover rally and travel.",
        "block_approved": 0,
        "district_approved": 1,
        "state_approved": 2,
        "ho_approved": 1,
        "block_approver_id": "a@b.com",
        "district_approver_id": "x@b.com",
        "state_approver_id": "c@b.com",
        "ho_approver_id": "d@b.com",
        "block_approver_name": "Prateek",
        "district_approver_name": "Krishna",
        "state_approver_name": "Aditya",
        "ho_approver_name": "Reetesh",
        "block_approved_date": "1694763307952",
        "district_approved_date": "1694763307952",
        "state_approved_date": "1694763307952",
        "ho_approved_date": "1694763307952",
        "block_arh_reason": "Prateek",
        "district_arh_reason": "Krishna",
        "state_arh_reason": "Aditya",
        "ho_arh_reason": "Reetesh"
    },
    {
        "user_id": "test@g.com",
        "date": "1694763307952",
        "description": "Planning to cover rally and travel.",
        "block_approved": 0,
        "district_approved": 1,
        "state_approved": 2,
        "ho_approved": 1,
        "block_approver_id": "a@b.com",
        "district_approver_id": "x@b.com",
        "state_approver_id": "c@b.com",
        "ho_approver_id": "d@b.com",
        "block_approver_name": "Prateek",
        "district_approver_name": "Krishna",
        "state_approver_name": "Aditya",
        "ho_approver_name": "Reetesh",
        "block_approved_date": "1694763307952",
        "district_approved_date": "1694763307952",
        "state_approved_date": "1694763307952",
        "ho_approved_date": "1694763307952",
        "block_arh_reason": "Prateek",
        "district_arh_reason": "Krishna",
        "state_arh_reason": "Aditya",
        "ho_arh_reason": "Reetesh"
    },
    {
        "user_id": "test@g.com",
        "date": "1694763307952",
        "description": "Planning to cover rally and travel.",
        "block_approved": 0,
        "district_approved": 1,
        "state_approved": 2,
        "ho_approved": 1,
        "block_approver_id": "a@b.com",
        "district_approver_id": "x@b.com",
        "state_approver_id": "c@b.com",
        "ho_approver_id": "d@b.com",
        "block_approver_name": "Prateek",
        "district_approver_name": "Krishna",
        "state_approver_name": "Aditya",
        "ho_approver_name": "Reetesh",
        "block_approved_date": "1694763307952",
        "district_approved_date": "1694763307952",
        "state_approved_date": "1694763307952",
        "ho_approved_date": "1694763307952",
        "block_arh_reason": "Prateek",
        "district_arh_reason": "Krishna",
        "state_arh_reason": "Aditya",
        "ho_arh_reason": "Reetesh"
    },
    {
        "user_id": "test@g.com",
        "date": "1694763307952",
        "description": "Planning to cover rally and travel.",
        "block_approved": 0,
        "district_approved": 1,
        "state_approved": 2,
        "ho_approved": 1,
        "block_approver_id": "a@b.com",
        "district_approver_id": "x@b.com",
        "state_approver_id": "c@b.com",
        "ho_approver_id": "d@b.com",
        "block_approver_name": "Prateek",
        "district_approver_name": "Krishna",
        "state_approver_name": "Aditya",
        "ho_approver_name": "Reetesh",
        "block_approved_date": "1694763307952",
        "district_approved_date": "1694763307952",
        "state_approved_date": "1694763307952",
        "ho_approved_date": "1694763307952",
        "block_arh_reason": "Prateek",
        "district_arh_reason": "Krishna",
        "state_arh_reason": "Aditya",
        "ho_arh_reason": "Reetesh"
    },
    {
        "user_id": "test@g.com",
        "date": "1694763307952",
        "description": "Planning to cover rally and travel.",
        "block_approved": 0,
        "district_approved": 1,
        "state_approved": 2,
        "ho_approved": 1,
        "block_approver_id": "a@b.com",
        "district_approver_id": "x@b.com",
        "state_approver_id": "c@b.com",
        "ho_approver_id": "d@b.com",
        "block_approver_name": "Prateek",
        "district_approver_name": "Krishna",
        "state_approver_name": "Aditya",
        "ho_approver_name": "Reetesh",
        "block_approved_date": "1694763307952",
        "district_approved_date": "1694763307952",
        "state_approved_date": "1694763307952",
        "ho_approved_date": "1694763307952",
        "block_arh_reason": "Prateek",
        "district_arh_reason": "Krishna",
        "state_arh_reason": "Aditya",
        "ho_arh_reason": "Reetesh"
    }
];


