import './ExpenseDetail.scss';
import Navbar from './../../src/components/navbar/Navbar';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import { Button, ButtonToolbar, Input, InputGroup } from 'rsuite';
import { NavLink } from 'react-router-dom';
import downloadIcon from './../assets/icons/download.svg';
import rupeeIcon from './../assets/icons/rupee.svg';
export default function ExpenseDetail() {
    return (
        <>
            <div className='container-flude border-bottom pb-3'>
                <Navbar></Navbar>

                <div className="container mt-5">
                    <div className="row">
                        <div className="col-10 back-to-news">
                            <NavLink to="/accounts" ><ArowBackIcon /> </NavLink>
                            Claim details
                        </div>
                        <div className="col-2">
                            <ButtonToolbar>
                                <Button className="mr-2" color="cyan" appearance="primary" >Approve</Button>
                                <Button className="mr-2" appearance="ghost" >Reject</Button>
                            </ButtonToolbar>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row mt-3 mb-5 ">
                    <div className="col-3 border-end">
                        <div className="news-title">After Argentina won FIFA World Cup, Lionel Messi hugged me and said :Emi Martinez reveals emotional moment after Qatar final</div>
                        <div className="news-title">After Argentina won FIFA World Cup, Lionel Messi hugged me and said :Emi Martinez reveals emotional moment after Qatar final</div>
                        <div><i>By Ramesh Ranjan</i></div>
                        <div className="news-title mt-3">Location</div>
                        <div>Baliya, UP</div>
                        <div className="news-title mt-3">Cliam Date</div>
                        <div>Jul 02, 2023 04:25 PM</div>

                        <div className="news-title mt-3">Approved By</div>
                        <div><strong>Block:</strong> krishna</div>
                        <div><strong>District:</strong>: krishna</div>
                        <div><strong>State:</strong>: krishna</div>
                        <div><strong>HO:</strong>: krishna</div>
                    </div>

                    <div className="col-8">
                        {/*Travel Expense*/}
                        <div className="row">
                            <div className="col">
                                <div><strong>Travel expense</strong></div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <label>Claimed:</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input value={740} disabled />
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <label>Approved :</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input value={740 } />
                                        </InputGroup>
                                    </div>
                                    <div className="col-8">
                                        <label>Attachments :</label>
                                        <div>
                                        <div className="attachment mt-1 mr-1">
                                            <img src={downloadIcon } /> Rail_ticket.pdf
                                        </div>
                                        <div className="attachment mt-1 mr-1">
                                            <img src={downloadIcon} /> bus_ticket.pdf
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Food Expense*/ }
                        <div className="row mt-3">
                            <div className="col">
                                <div><strong>Food expense</strong></div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <label>Claimed:</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input value={791} disabled  />
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <label>Approved :</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input value="791" />
                                        </InputGroup>
                                    </div>
                                    <div className="col-8">
                                        <label>Attachments :</label>
                                        <div>
                                            <div className="attachment mt-1 mr-1">
                                                <img src={downloadIcon} /> Rail_ticket.pdf
                                            </div>
                                            <div className="attachment mt-1 mr-1">
                                                <img src={downloadIcon} /> bus_ticket.pdf
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Hotel Expense*/}
                        <div className="row mt-3">
                            <div className="col">
                                <div><strong>Hotel expense</strong></div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <label>Claimed:</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input disabled value={1200} />
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <label>Approved :</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input value={1200 } />
                                        </InputGroup>
                                    </div>
                                    <div className="col-8">
                                        <label>Attachments :</label>
                                        <div>
                                            <div className="attachment mt-1 mr-1">
                                                <img src={downloadIcon} /> Rail_ticket.pdf
                                            </div>
                                            <div className="attachment mt-1 mr-1">
                                                <img src={downloadIcon} /> bus_ticket.pdf
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Other expense*/ }
                        <div className="row mt-3">
                            <div className="col">
                                <div><strong>Other expense</strong></div>
                                <div className="row mt-2">
                                    <div className="col-2">
                                        <label>Claimed:</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input disabled value={0} />
                                        </InputGroup>
                                    </div>
                                    <div className="col-2">
                                        <label>Approved :</label>
                                        <InputGroup>
                                            <InputGroup.Addon> <img src={rupeeIcon} /></InputGroup.Addon>
                                            <Input value={0 } />
                                        </InputGroup>
                                    </div>
                                    <div className="col-8">
                                        <label>Attachments :</label>
                                        <div>
                                            <div className="attachment mt-1 mr-1">
                                                <img src={downloadIcon} /> Rail_ticket.pdf
                                            </div>
                                            <div className="attachment mt-1 mr-1">
                                                <img src={downloadIcon} /> bus_ticket.pdf
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>

              

            </div>
            
        </>
    );
}
