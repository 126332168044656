import React, { useEffect, useMemo, useState } from "react";
import Navbar from './../../src/components/navbar/Navbar';
import ExpenseComment from "../components/modal/ExpenseComment";
import previousIcon from './../assets/icons/previous.svg';
import nextIcon from './../assets/icons/next.svg';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import PauseOutlineIcon from '@rsuite/icons/PauseOutline';
import { NavLink } from 'react-router-dom';
import downloadIcon from './../assets/icons/download.svg';
import rupeeIcon from './../assets/icons/rupee.svg';
import { DateRangePicker, CheckPicker, Loader, Placeholder, Table, Button, ButtonToolbar, Input } from 'rsuite';
import './Account.scss';
import { NewsStatus,  ExpenseData } from "./../routes/Paths";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;


export default function Account() {
    const [loader, setLoader] = useState(true);
    const [locations, setLocations] = useState([]);
    const [activeNewsType, setNewsType] = useState("Approved");
    const [searchText, setSearch] = useState("");
    const [activeState, setState] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [activeDist, setDist] = useState([]);
    const [distData, setDistData] = useState([]);
    const [activeVillage, setVillage] = useState([]);
    const [villageData, setVillageData] = useState([]);
    const [activeDateRange, setDateRange] = useState([]);

    const [activeExpense, setActiveExpense] = useState({});
    const [open, setOpen] = useState(false);
    const [actionType, setActionType] = useState("");

    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);

    const data = ExpenseData.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    });


    const changeNewsStatus = (status) => { if (status == activeNewsType) status = ""; setNewsType(status); }
    setTimeout(() => { setLoader(false) }, 1000);

    // Add default value on page load
    useEffect(() => {
        getLocation();
    }, []);

    const handleSearchChange = searchText => setSearch(searchText);

    const handleStateChange = state => {
        let data = locations.filter(l => state.includes(l.state));
        let distData = [...new Set(data.map(item => item.dist))].map(item => ({ label: item, value: item }));
        setState(state);
        setDistData(distData);
    }
    const handleDistChange = dist => {
        let data = locations.filter(l => activeState.includes(l.state))
            .filter(d => dist.includes(d.dist))
        let villageData = [...new Set(data.map(item => item.village))].map(item => ({ label: item, value: item }));
        setDist(dist);
        setVillageData(villageData);
    }

    const handleVillageChange = village => setVillage(village);
    const handleDateRangeChange = dateRange => setDateRange(dateRange);
    const handleChangeLimit = dataKey => {
        setPage(1);
        setLimit(dataKey);
    };
    async function getLocation() {
        try {
            const response = await fetch('https://tnp-utils.s3.ap-south-1.amazonaws.com/location.json');
            const locations = await response.json();
            let locData = [];
            let statesArray = [];
            let temp = [];

            locations.forEach((location) => {
                Object.keys(location).forEach((state, i) => {
                    statesArray.push(state);
                    location[state].forEach((distObj, j) => {
                        Object.keys(distObj).forEach((dist, k) => {
                            distObj[dist].forEach(pinObj => {
                                Object.keys(pinObj).forEach(pin => {
                                    pinObj[pin].forEach(village => { locData.push({ "state": state, "dist": dist, "pin": pin, "village": village }); });
                                })
                            })
                        })
                    });
                });
            });
            let stateDataObj = [...new Set(locData.map(item => item.state))].map(item => ({ label: item, value: item }));
            setLocations(locData);
            setStateData(stateDataObj);
        } catch (err) {
            console.log(err);
        }
    }
    const getStatusIcon = id => {
        if (id == 1) return <div className="approved"><CheckOutlineIcon /></div>;
        if (id == 2) return <div className="rejected"><CloseOutlineIcon /></div>;
        if (id == 3) return <div className="hold"><PauseOutlineIcon /></div>;
    }
    const getDateTime = timestamp => {
        let d = new Date(timestamp);
        return <div><span className="r-date">{d.toDateString()}</span><span className="r-time">{d.toLocaleTimeString()} </span></div>;
    }

    const handleOpen = (actionType, expense) => { setActionType(actionType); setActiveExpense(expense); setOpen(true); };
    const handleClose = () => setOpen(false);
    const submitReason = (expense) => { let index = data.findIndex(e => e.expense_id == expense.expense_id); data[index] = expense; setOpen(false) }

    return (
        <div className='container-flude account'>
            <Navbar></Navbar>
            {loader && <Loader center content="Loading..." vertical />}
            {!loader &&
                <>
                    <div className='container mt-3 flex-container'>
                        <div className='row news-type' >
                            <div className={`col ${activeNewsType == "New" ? "active" : ""}`} onClick={() => changeNewsStatus('New')}>New uploaded news</div>
                            <div className={`col ${activeNewsType == "Approved" ? "active" : ""}`} onClick={() => changeNewsStatus('Approved')}>Approved News</div>
                            <div className={`col ${activeNewsType == "Rejected" ? "active" : ""}`} onClick={() => changeNewsStatus('Rejected')}>Rejected News</div>
                            <div className={`col ${activeNewsType == "Hold" ? "active" : ""}`} onClick={() => changeNewsStatus('Hold')}>Hold News</div>
                        </div>
                </div>

                <div className="container ">
                    {/* Report Index Start */}
                    <div className='row mt-3'>
                        <div className='col-md-3'>
                            <div className='card registered'>
                                <div className='index'>₹273100</div>
                                <div className='index-title'>Total Claim   <br /> Amount</div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='card accepted'>
                                <div className='index'>₹260000</div>
                                <div className='index-title'>Amount <br />  Approved</div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='card rejected'>
                                <div className='index'>₹1318200</div>
                                <div className='index-title'>Amount <br />  Rejected</div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className='card pending'>
                                <div className='index'>₹220000</div>
                                <div className='index-title'>Amount <br />  Disbursed</div>
                            </div>
                        </div>
                    </div>
                </div>


                    <div className='container'>
                        <div className="row mb-3">
                            <div className='col-2'>
                 
                            <Input placeholder="Search" onChange={handleSearchChange} />
                            </div>
                            <div className='col-2'>
                                <DateRangePicker
                                    onChange={handleDateRangeChange} />
                            </div>

                            <div className='col-2'>
                                <CheckPicker
                                    data={stateData}
                                    onChange={handleStateChange}
                                    style={{ width: 224 }} />
                            </div>

                            <div className='col-2'>
                                <CheckPicker
                                    data={distData}
                                    onChange={handleDistChange}
                                    style={{ width: 224 }} />
                            </div>

                            <div className='col-2'>
                                <CheckPicker
                                    data={villageData}
                                    onChange={handleVillageChange}
                                    style={{ width: 224 }} />
                            </div>



                            <div className='col-2 text-end'>
                                <button className='btn btn-outline-info'><img src={downloadIcon} /> Download</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Table
                                    height={300}
                                rowHeight={rowData => {
                                    if (rowData?.news_title.length > 1) {
                                        return rowData?.news_title.length*65;
                                    }
                                    return 65;
                                }}
                                    headerHeight={60}
                                    data={data}
                                    onRowClick={rowData => {
                                        console.log(rowData);
                                    }}
                                >
                                    <Column width={300} align="left"  >
                                    <HeaderCell >News heading</HeaderCell>
                                        <Cell >
                                        {rowData => rowData.news_title.map(news => (
                                            <div className="news-seperator">   <NavLink to={"/news-detail/" + news} >{news}</NavLink></div>
                                        ))}
                                        </Cell>
                                    </Column>

                                    <Column width={100}>
                                        <HeaderCell>Photo/Video</HeaderCell>
                                    <Cell>
                                        {rowData => rowData.news_title.map((news,index) => (
                                            <div className="news-seperator">   {rowData.news_image_links.length} Photo, <br />{rowData.news_video_links.length} Videos</div>
                                        ) )}
                                       </Cell>
                                    </Column>

                                    <Column width={100}>
                                        <HeaderCell>Location</HeaderCell>
                                    <Cell >
                                        {rowData => rowData.news_district.map((district,index) => (
                                            <div className="news-seperator">  {district} <br /> {rowData.news_state[index]}</div>
                                        ))}
                                    </Cell>
                                    </Column>

                                    <Column width={150}>
                                        <HeaderCell>Upload Date</HeaderCell>
                                    <Cell>{rowData => (<span>{getDateTime(rowData.expense_reported_date)}</span>)}</Cell>
                                </Column>

                                <Column width={120}>
                                    <HeaderCell align="center">Claim Amount</HeaderCell>
                                    <Cell align="center">{rowData => (<span><img className="cur-rupee" src={rupeeIcon} />{rowData.travel_expense}</span>)}</Cell>
                                </Column>

                                    <ColumnGroup header="Approved By" align="center">
                                        <Column width={60} colSpan={2}>
                                            <HeaderCell>Block</HeaderCell>
                                            <Cell >
                                                {rowData => (<>{getStatusIcon(rowData.block_approved)}<span className="approver-name">{rowData.block_approved != 0 && rowData.block_approver_name}</span></>)}
                                            </Cell>
                                        </Column>
                                        <Column width={80}>
                                            <HeaderCell>District</HeaderCell>
                                            <Cell >
                                                {rowData => (<>{getStatusIcon(rowData.district_approved)}<span className="approver-name">{rowData.district_approved != 0 && rowData.district_approver_name}</span></>)}
                                            </Cell>
                                        </Column>
                                        <Column width={60}>
                                            <HeaderCell>State</HeaderCell>
                                            <Cell >
                                                {rowData => (<>{getStatusIcon(rowData.state_approved)}<span className="approver-name">{rowData.state_approved != 0 && rowData.state_approver_name}</span></>)}
                                            </Cell>

                                        </Column>
                                        <Column width={60}>
                                            <HeaderCell>HO</HeaderCell>
                                            <Cell >
                                                {rowData => (<>{getStatusIcon(rowData.ho_approved)}<span className="approver-name">{rowData.ho_approved != 0 && rowData.ho_approver_name}</span></>)}
                                            </Cell>
                                        </Column>
                                    </ColumnGroup>

                                    <Column width={180} align="center" fixed="right">
                                        <HeaderCell>Action</HeaderCell>

                                    <Cell style={{ padding: '6px', 'text- align': 'left' }}>
                                        
                                        {rowData => (
                                            <>
                                                <div className="view-expense"><NavLink to={"/expense-detail/" + rowData.expense_id} >View / Edit</NavLink></div>
                                            <ButtonToolbar>                                                    
                                                    <Button size="sm" className="mr-2" color="cyan" appearance="primary" onClick={() => handleOpen("Approve",rowData)}>Approve</Button>
                                                    <Button size="sm" className="mr-2" appearance="ghost" onClick={() => handleOpen("Reject", rowData)}>Reject</Button>
                                                </ButtonToolbar></>
                                            )}
                                        
                                        </Cell>
                                    </Column>
                                </Table>

                            </div>
                        </div>

                        <div className='row mt-3 mb-3'>
                            <div className='col-md-4'>
                                <span>Show rows per page </span>
                                <select className='page ml-2'>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                </select>
                            </div>
                            <div className='col-md-4'>
                                <div className='row'>
                                    <div className='previous col-2'><img src={previousIcon} /></div>
                                    <div className='current col-4 text-center pt-2 text-dark'><strong>1-10 </strong> of <span>12</span></div>
                                    <div className='next col-2'><img src={nextIcon} /></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }

            <ExpenseComment
                modalClose={handleClose}
                open={open}
                expense={activeExpense}
                submitReason={submitReason }
                actionType={actionType}></ExpenseComment>
        </div>
    );
}
