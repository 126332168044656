import {
	CognitoUserPool,
	CognitoUser,
} from 'amazon-cognito-identity-js';

var poolData = {
	UserPoolId: process.env.REACT_APP_UserPoolId,
	ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

async function resendEmailConfirmation(username) {
	var userData = {
		Username: username,
		Pool: userPool,
	};

	var cognitoUser = new CognitoUser(userData);

	return new Promise((resolve, reject) =>
		cognitoUser.resendConfirmationCode(
			function (err, result) {
				if (err) {
					alert(err.message || JSON.stringify(err));
					reject(err);
					return;
				}
				alert(
					'Confirmation code sent again, please check your email.',
				);

				resolve(result);
			},
		),
	);
}

export default resendEmailConfirmation;
