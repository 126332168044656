import { useEffect, useState } from 'react';
import './registerForm.scss';
import backIcon from './../../assets/icons/back-arraw.svg'; 
import {useNavigate} from "react-router-dom";
import Input from './Input';
import Checkbox from './Checkbox';
import Button from '../Button';

export default function RegisterFormPersonal(props){
    let navigate = useNavigate();
    const [emailInput, setEmailInput] = useState(false);
    const [passwordInput, setPasswordInput] = useState(false);
 
    function detectEmailInput(e) {
    setEmailInput(e.target.value);
  }
  function detectPasswordInput(e) {
    setPasswordInput(e.target.value);
  }
  useEffect(() => {
    console.log('running conditional');
    console.log('emailInput', emailInput);
    console.log('passwordInput', passwordInput);
    if (emailInput !== '' && passwordInput !== '') {
      document.getElementById('loginSubmit').classList.add('submitProg100');
    } else if (emailInput !== '' || passwordInput !== '') {
      document.getElementById('loginSubmit').classList.add('submitProg50');
      document.getElementById('loginSubmit').classList.remove('submitProg100');
    } else {
      document.getElementById('loginSubmit').classList.remove('submitProg50');
      document.getElementById('loginSubmit').classList.remove('submitProg100');
    }
  });
    return(
        <div className="loginFormContainer">
        <div className="reg-head">
            <div className='back'>
            <img onClick ={()=>{ navigate("/register")}} src={backIcon} alt="Back" />
            </div>
            <div className='heade-title'>
            <h2>Complete your registration</h2>
            </div>
        </div>

        <form id="loginForm">
        <Input
          type="text"
          inputId="fullName"
          required="required"
          labelId="fullName"
          labelString="Your Full Name"
          detectInput={detectEmailInput}
        />
         <Input
          type="password"
          inputId="password"
          required="required"
          labelId="password"
          labelString="Enter Password"
          detectInput={detectEmailInput}
        />
         <Input
          type="password"
          inputId="cPassword"
          required="required"
          labelId="cPassword"
          labelString="Enter Confirm Password"
          detectInput={detectEmailInput}
        />
      
      </form>
     
      <div className="checkboxContainer">
      <button type="submit" form="loginForm"  id="loginSubmit">Verify Number</button>
      </div>
    
      </div>
    )
}