import { useEffect, useState } from "react";
import Input from "./Input";
import "./ProfileForm.scss";
import userIcon from "./../../assets/icons/user-icon.png";
import { useNavigate } from "react-router-dom";
import signUp from "../../logic/auth/signUp";
import Network from "../../logic/api";
import { CreateUser } from "../../logic/store/actions/actionConstants";
import { useDispatch, useSelector } from "react-redux";
import store from "../../logic/store/store";
import {
  UpdateUserProfile,
  UploadUserPicture,
  GetUserProfile,
} from "../../logic/store/actions/actionConstants";

import {
  DatePicker,
  SelectPicker,
  Loader,
  Placeholder,
  Table,
  Button,
  ButtonToolbar,
} from "rsuite";

export default function ProfileForm(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState("");
  const [fullNameInput, setFullNameInput] = useState(
    user.name ? user.name : ""
  );
  const [completeAddressInput, setCompleteAddressInput] = useState(
    user.address ? user.address : ""
  );

  const [locations, setLocations] = useState([]);
  const [activeState, setState] = useState(user.state ? user.state : null);
  const [stateData, setStateData] = useState([]);
  const [activeDist, setDist] = useState(user.district ? user.district : null);
  const [distData, setDistData] = useState([]);
  const [gender, setGender] = useState(user.gender ? user.gender : null);
  const [genderData, setGenderData] = useState([
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]);

  const [activePincode, setPincode] = useState(
    user.pincode ? user.pincode : null
  );
  const [pincodeData, setPincodeData] = useState([]);

  const [activeVillage, setVillage] = useState(user.block ? user.block : null);
  const [villageData, setVillageData] = useState([]);

  const [selectedImage, setSelectedImage] = useState(
    user.imageURL ? user.imageURL : null
  );

  const [educationInput, setEducationInput] = useState(
    user.education ? user.education : null
  );
  const [educationData, setEducationData] = useState([
    { value: "Basic Education", label: "Basic Education" },
    {
      value: "10th Standard (High School)",
      label: "10th Standard (High School)",
    },
    {
      value: "12th Standard (Senior Secondary School)",
      label: "12th Standard (Senior Secondary School)",
    },
    { value: "Graduate", label: "Graduate" },
    { value: "Post-Graduate", label: "Post-Graduate" },
  ]);

  const [activeDate, setDate] = useState(user.dob ? user.dob : null);

  useEffect(() => {
    if (user.state) {
      handleStateChange(user.state);
      handleDistChange(user.district);
      handlePincodeChange(user.pincode);
      handleVillageChange(user.block);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (user.name === null) {
        try {
          const result = await dispatch(
            Network.networkCall({
              action: GetUserProfile,
              data: {
                user_id: user.user_id,
              },
            })
          );
          if (result.success) {
            setLoading(false);
            console.log("Successfully retrieved user profile.", result);
          }
        } catch (error) {
          setLoading(false);
          console.log("Failed to get user profile.", error);
        }
      }
      setLoading(false);
    };
    fetchData();
    getLocation();
  }, []);

  function detectFullNameInput(e) {
    setFullNameInput(e.target.value);
  }

  const handleDateChange = (date) => setDate(date);

  function handleGenderChange(value) {
    setGender(value);
  }

  function detectEducationInput(value) {
    setEducationInput(value);
  }

  function detectCompleteAddressInput(e) {
    setCompleteAddressInput(e.target.value);
  }

  async function getLocation() {
    try {
      const response = await fetch(
        "https://tnp-utils.s3.ap-south-1.amazonaws.com/location.json"
      );
      const locations = await response.json();
      let locData = [];
      let statesArray = [];
      let temp = [];
      locations.forEach((location) => {
        Object.keys(location).forEach((state, i) => {
          statesArray.push(state);
          location[state].forEach((distObj, j) => {
            Object.keys(distObj).forEach((dist, k) => {
              distObj[dist].forEach((pinObj) => {
                Object.keys(pinObj).forEach((pin) => {
                  pinObj[pin].forEach((village) => {
                    locData.push({
                      state: state,
                      dist: dist,
                      pin: pin,
                      village: village,
                    });
                  });
                });
              });
            });
          });
        });
      });
      let stateDataObj = [...new Set(locData.map((item) => item.state))].map(
        (item) => ({ label: item, value: item })
      );
      setLocations(locData);
      setStateData(stateDataObj);
    } catch (err) {
      console.log(err);
    }
  }

  const handleStateChange = (state) => {
    let data = locations.filter((l) => state.includes(l.state));
    let distData = [...new Set(data.map((item) => item.dist))].map((item) => ({
      label: item,
      value: item,
    }));
    setState(state);
    setDistData(distData);
  };
  const handleDistChange = (dist) => {
    let data = locations
      .filter((l) => activeState.includes(l.state))
      .filter((d) => dist.includes(d.dist));
    let pincodeData = [...new Set(data.map((item) => item.pin))].map(
      (item) => ({ label: item, value: item })
    );
    setDist(dist);
    setPincodeData(pincodeData);
  };

  const handlePincodeChange = (pin) => {
    let data = locations
      .filter((l) => activeState.includes(l.state))
      .filter((d) => activeDist.includes(d.dist))
      .filter((p) => pin.includes(p.pin));
    let villageData = [...new Set(data.map((item) => item.village))].map(
      (item) => ({ label: item, value: item })
    );
    setPincode(pin);
    setVillageData(villageData);
  };

  const handleVillageChange = (village) => {
    setVillage(village);
  };

  useEffect(() => {
    if (
      fullNameInput !== "" &&
      completeAddressInput !== "" &&
      activeDate &&
      activeDist &&
      activePincode &&
      activeState &&
      activeVillage &&
      gender &&
      educationInput
    ) {
      document.getElementById("registerSubmit").classList.add("submitProg50");
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    return dispatch(
      Network.networkCall({
        action: UpdateUserProfile,
        data: {
          user_id: user.user_id,
          creation_ts: user.creation_ts,
          name: fullNameInput,
          block: activeVillage,
          district: activeDist,
          state: activeState,
          gender: gender,
          pincode: activePincode,
          address: completeAddressInput,
          education: educationInput,
          dob: activeDate,
          img: selectedImage,
        },
      })
    )
      .then(function (result) {
        if (result.success) {
          setLoading(false);
          navigate("/audition");
          console.log("Successfully Update User Profile.", result);
        } else {
          setLoading(false);
          console.log(result);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Failed to Update User Profil." + error);
      });
  };

  const handleImageChange = async (event) => {
    const file = await event.target.files[0];
    console.log('Selected image:', file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        const mime = reader.result.split(";")[0].split(":")[1];
        const fileName = file.name;
        uploadImage(base64, mime, fileName);
      };
      reader.readAsDataURL(file);
    }
  };

  function uploadImage(base64, mime, fileName) {
    setLoading(true);
    return dispatch(
      Network.networkCall({
        action: UploadUserPicture,
        data: {
          user_id: user.user_id,
          creation_ts: user.creation_ts,
          key: fileName,
          mime: mime,
          image: base64,
        },
      })
    )
      .then(function (result) {
        setLoading(false);
        if (result.success) {
          setSelectedImage(result.data.imageURL);
          console.log(
            "Successfully Upload User Picture.",
            result.data.imageURL
          );
        } else {
          console.log(result.data.imageURL);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Failed to Upload User Picture." + error);
      });
  }
  console.log("the data is ", activeDate);

  return (
    <div className="ProfileFormContainer">
      {loading && (
        <Loader backdrop center size="md" content="Loading..." vertical />
      )}

      <form id="registerForm" onSubmit={handleSubmit}>
      <div className="image-picker-container">
        <img
          src={selectedImage ? selectedImage : userIcon}
          alt="Selected"
          className="preview-image"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          id="fileInput"
          className="Profile-file-input"
          // Hide the input element
        />
        <button onClick={handleImageChange} className="custom-file-input">
          Update Image
        </button>
      </div>

        <Input
          type="text"
          inputId="fullName"
          required="required"
          labelId="registefullNameLabel"
          labelString="Full Name"
          value={fullNameInput}
          detectInput={detectFullNameInput}
        />

        <DatePicker
          placeholder="Select Date of Birth"
          format="dd-MM-yyyy"
          onChange={handleDateChange}
          defaultValue={new Date(activeDate)}
          style={{ width: "100%" }}
        />

        <SelectPicker
          searchable={false}
          cleanable={false}
          data={genderData}
          label="Gender"
          value={gender}
          onChange={handleGenderChange}
          style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
        />

        <SelectPicker
          searchable={false}
          cleanable={false}
          data={educationData}
          label="Education"
          value={educationInput}
          onChange={detectEducationInput}
          style={{ width: "100%", marginBottom: 15 }}
        />

        <Input
          type="text"
          inputId="CompleteAddress"
          required="required"
          labelId="registeCompleteAddressLabel"
          labelString="Complete Address"
          value={completeAddressInput}
          detectInput={detectCompleteAddressInput}
        />

        <SelectPicker
          searchable={false}
          cleanable={false}
          data={stateData}
          label="State"
          defaultValue={activeState}
          onChange={handleStateChange}
          style={{ width: "100%" }}
        />
        <SelectPicker
          searchable={false}
          cleanable={false}
          data={distData}
          label="District"
          defaultValue={activeDist}
          onChange={handleDistChange}
          style={{ width: "100%", marginTop: 15 }}
        />
        <SelectPicker
          searchable={false}
          cleanable={false}
          data={pincodeData}
          label="Pincode"
          defaultValue={activePincode}
          onChange={handlePincodeChange}
          style={{ width: "100%", marginTop: 15 }}
        />
        <SelectPicker
          searchable={false}
          cleanable={false}
          data={villageData}
          label="Block"
          defaultValue={activeVillage}
          onChange={handleVillageChange}
          style={{ width: "100%", marginTop: 15 }}
        />
      </form>

      <div className="checkboxContainer">
        <button type="submit" form="registerForm" id="registerSubmit">
          Register
        </button>
      </div>
    </div>
  );
}
