import { useEffect } from 'react';
import './input.scss';

export default function Input(props) {
  function createLabel() {
    let str = props.labelString;
    let split = str.split('');
    document.getElementById(props.labelId).innerHTML = split
      .map((letter) => {
        if (letter === ' ') {
          return `<span>${String.fromCharCode(160)}</span>`;
        } else {
          return `<span>${letter}</span>`;
        }
      })
      .join('');
  }
  useEffect(() => {
    createLabel();
  }, []);

  // animating input and label on focus
  useEffect(() => {
    const input = document.getElementById(props.inputId);
    const spanTags = document.getElementById(props.labelId).childNodes;
    if (props.value) {
      document.getElementById(props.inputId).focus();
    }
    input.addEventListener('focusin', function () {
      for (let i = 0; i < spanTags.length; i++) {
        spanTags[i].classList.add('animatedLabel');
      }
    });
    input.addEventListener('focusout', function () {
      if (input.value === '') {
        for (let i = 0; i < spanTags.length; i++) {
          spanTags[i].classList.remove('animatedLabel');
        }
      }
    });
  });

  // setting focus to input if click on label
  function getFocus() {
    document.getElementById(props.inputId).focus();
  }

  return (
    <div className="inputGroup">
      <input
        id={props.inputId}
        className={'inputText ' + props.className}
        type={props.type}
        title={props.title}
        required={props.required}
        onChange={(e) => props.detectInput(e)}
        value={props.value}
      />
      <label
        id={props.labelId}
        className="inputText__label"
        htmlFor="mail"
        onClick={getFocus}
      ></label>
    </div>
  );
}
