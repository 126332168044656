import { Mobile, Tablet, Desktop } from '../styles/Responsive';
import CreateProfileForm from '../components/forms/CreateProfileForm';
import ImgSlider from '../components/imgSlider/ImgSlider';
import './register.scss';

export default function CreateProfile(props) {

  return (
    <>
      <Mobile>
        <div className="m-loginContainer">
          <CreateProfileForm getToken={props.getToken} />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-loginContainer">
          <div className="t-loginContent__grid">
            <CreateProfileForm getToken={props.getToken} />
            <ImgSlider />
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="loginContainer">
          <ImgSlider />
          <CreateProfileForm getToken={props.getToken} />
        </div>
      </Desktop>
    </>
  );
}
