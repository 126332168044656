import { Modal, Button, Placeholder } from 'rsuite';
import './ExpenseComment.scss';
import { useState, useMemo, useEffect } from "react";
import { Roles, Levels } from "./../../routes/Paths";

export default function ExpenseComment(props) {
    const handleClose = () => props.modalClose();
    const handleConfirm = () => { props.submitReason(props.expense); }
    console.log(props.expense.news_title);
    return (

        <Modal size="sm" open={props.open} onClose={handleClose}>
            <Modal.Header>
                <Modal.Title><strong className="text-danger">Claim {props.actionType} reason for News</strong></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="model-body">
                <div className='profile'>
                    <div className='col-12'>
                        {props.expense.news_title?.map(news => (<div className="news-title">{news}</div>))}
                    </div>
                </div>

                <div className='profile'>
                    <div className='col-12'>
                        <textarea className="reason-text" placeholder="Enter your reason"></textarea>
                    </div>
                </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" onClick={handleConfirm} className="btn btn-default">Submit</Button>
                <Button onClick={handleClose} appearance="subtle">
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal>

    );
}
