import { useEffect, useState } from "react";
import Input from "./Input";
import Checkbox from "./Checkbox";
import Button from "../Button";
import "./forgetPasswordForm2.scss";
import backIcon from "./../../assets/icons/back-arraw.svg";
import { useNavigate } from "react-router-dom";
import resetToken from "../../logic/auth/resetPassword";
import { useSelector } from "react-redux";

export default function ForgetPasswordForm2(props) {
  let navigate = useNavigate();
  // styling submit button based on form input
  const user = useSelector((state) => state.user);
  const [confirmationCode, setConfirmationCode] = useState(false);
  const [passwordInput, setPasswordInput] = useState(false);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [loading, setLoading] = useState(false);

  function detectConfirmationCode(e) {
    setConfirmationCode(e.target.value);
  }
  function detectPasswordInput(e) {
    setPasswordInput(e.target.value);
  }

  function detectConfirmPasswordInput(e) {
    setConfirmPasswordInput(e.target.value);
  }
  useEffect(() => {
    console.log("running conditional");
    console.log("confirmationCodeInput", confirmationCode);
    console.log("passwordInput", passwordInput);
    console.log("confirmPasswordInput", confirmPasswordInput);
    if (confirmationCode !== '' && passwordInput !== ''&& confirmPasswordInput !== '' ) {
      document.getElementById('loginSubmit').classList.add('submitProg100');
    } else {
      document.getElementById('loginSubmit').classList.remove('submitProg50');
      document.getElementById('loginSubmit').classList.remove('submitProg100');
    }
  });

  const handleSubmit = async (e) => {
    resetToken(user.user_id, confirmationCode, passwordInput)
      .then((res) => {
        setLoading(false)
        console.log("this is the response of resetToken", res);
        navigate("/auth/login");
      })
      .catch((error) => {
        setLoading(false)
        console.log("Failed to reset password.", error);
      });
  };

  return (
    <div className="loginFormContainer">
      <div className="reg-head">
        <div className="back">
          <img
            onClick={() => {
              navigate("/auth/login");
            }}
            src={backIcon}
            alt="Back"
          />
        </div>
        <div className="heade-title">
          <h2>Set Password</h2>
          <p>The password should be at least 8 characters long.</p>
        </div>
      </div>

      <br />
      <form id="loginForm" onSubmit={handleSubmit}>
        <Input
          type="number"
          inputId="mobileOtp"
          required="ConfirmationCode"
          labelId="ConfirmationCode"
          labelString="Confirmation Code"
          detectInput={detectConfirmationCode}
        />

        <Input
          type="password"
          inputId="password"
          required="required"
          labelId="registePasswordLabel"
          labelString="Password"
          detectInput={detectPasswordInput}
        />
        <Input
          type="password"
          inputId="cPassword"
          required="required"
          labelId="registeConfirmPasswordLabel"
          labelString="Confirm Password"
          detectInput={detectConfirmPasswordInput}
        />
      </form>

      <div className="checkboxContainer">
        <button type="submit" form="loginForm" id="loginSubmit">
        Submit
        </button>
      </div>
    </div>
  );
}
