import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	news: [],
};

export const newsSlice = createSlice({
	name: 'news',
	initialState,
	reducers: {},
	extraReducers: {
		RejectNewsSuccess: (state, action) => {
			let News = {...state.news};
			let RejectedNewsId = action.payload.news_id;
			News[RejectedNewsId].rejected = true;
		},
		RejectNewsFailure: (state, action) => {
			let News = {...state.news};
			let RejectedNewsId = action.payload.news_id;
			News[RejectedNewsId].rejected = false;
		}
	},
});

export const news = state => state.news;
export default newsSlice.reducer;