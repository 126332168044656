import { Mobile, Tablet, Desktop } from '../styles/Responsive';
import ConfirmEmailAndPhoneForm from '../components/forms/ConfirmEmailAndPhoneForm';
import ImgSlider from '../components/imgSlider/ImgSlider';
import { useParams } from 'react-router-dom'
import './register.scss';

export default function ConfirmEmailAndPhone(props) {
  const { email, phone, name } = useParams();
  console.log('Props : ' + JSON.stringify(props));
  return (
    <>
      <Mobile>
        <div className="m-loginContainer">
          <ConfirmEmailAndPhoneForm email={email} name={name}/>
        </div>
      </Mobile>
      <Tablet>
        <div className="t-loginContainer">
          <div className="t-loginContent__grid">
            <ConfirmEmailAndPhoneForm email={email} name={name} />
            <ImgSlider />
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="loginContainer">
          <ImgSlider />
          <ConfirmEmailAndPhoneForm email={email} name={name} />
        </div>
      </Desktop>
    </>
  );
}
