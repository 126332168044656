import React from "react";
import "./PrivacyPolicy.scss";

export default function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicyDiv">
      <p>
        <strong>PRIVACY NOTICE</strong>
      </p>

      <p>Last updated August 29, 2023</p>

      <p>
        This privacy notice for TNP News (“Company,” “we,” “us,” or “our“),
        describes how and why we might collect, store, use, and/or share
        (“process“) your information when you use our services (“Services“),
        such as when you:
      </p>

      <ul>
        <li>
          Download and use our mobile application (TNP Reporter App), or any
          other application of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>

      <p>
        Questions or concerns? Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at{" "}
        <a href="mailto:tnpnews9@gmail.com">tnpnews9@gmail.com</a>.
      </p>

      <p>
        <strong>SUMMARY OF KEY POINTS</strong>
      </p>

      <p>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for. You can also click{" "}
        <a href="#table-of-contents">here</a> to go directly to our table of
        contents.
      </p>

      <p>
        <strong>What personal information do we process?</strong> When you
        visit, use, or navigate our Services, we may process personal
        information depending on how you interact with TNP News and the
        Services, the choices you make, and the products and features you use.
        Click <a href="#what-information-do-we-collect">here</a> to learn more.
      </p>

      <p>
        <strong>Do we process any sensitive personal information?</strong> We do
        not process sensitive personal information.
      </p>

      <p>
        <strong>Do we receive any information from third parties?</strong> We do
        not receive any information from third parties.
      </p>

      <p>
        <strong>How do we process your information?</strong> We process your
        information to provide, improve, and administer our Services,
        communicate with you, for security and fraud prevention, and to comply
        with law. We may also process your information for other purposes with
        your consent. We process your information only when we have a valid
        legal reason to do so. Click{" "}
        <a href="#how-do-we-process-your-information">here</a> to learn more.
      </p>

      <p>
        <strong>
          In what situations and with which parties do we share personal
          information?
        </strong>{" "}
        We may share information in specific situations and with specific third
        parties. Click here to learn more.
      </p>

      <p>
        <strong>How do we keep your information safe?</strong> We have
        organizational and technical processes and procedures in place to
        protect your personal information. However, no electronic transmission
        over the internet or information storage technology can be guaranteed to
        be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals, or other unauthorized third parties will not be able to
        defeat our security and improperly collect, access, steal, or modify
        your information. Click here to learn more.
      </p>

      <p>
        <strong>What are your rights?</strong> Depending on where you are
        located geographically, the applicable privacy law may mean you have
        certain rights regarding your personal information. Click here to learn
        more.
      </p>

      <p>
        <strong>How do you exercise your rights?</strong> The easiest way to
        exercise your rights is by filling out our data subject request form
        available here: <a href="https://tnpnews.in/">https://tnpnews.in/</a>, or by contacting us. We will
        consider and act upon any request in accordance with applicable data
        protection laws.
      </p>

      <p>
        Want to learn more about what TNP News does with any information we
        collect? Click here to review the notice in full.
      </p>

      <p>
        <strong>TABLE OF CONTENTS</strong>
      </p>

      <ol>
        <li>WHAT INFORMATION DO WE COLLECT?</li>

        <li>HOW DO WE PROCESS YOUR INFORMATION?</li>

        <li>
          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
        </li>

        <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>

        <li>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>

        <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>

        <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>

        <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>

        <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>

        <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>

        <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>

        <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>

        <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>

        <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>

        <li>
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </li>

        <li>ACCEPTABLE USE – WHAT CAN YOU DO AND WHEN DO WE STEP IN?</li>
      </ol>

      <p>
        <strong>WHAT INFORMATION DO WE COLLECT?</strong>
      </p>

      <strong>Personal information you disclose to us</strong>

      <p>
        <strong>In Short:</strong> We collect personal information that you
        provide to us.
      </p>

      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>

      <p>
        <strong>Personal Information Provided by You.</strong> The personal
        information that we collect depends on the context of your interactions
        with us and the Services, the choices you make, and the products and
        features you use. The personal information we collect may include the
        following:
      </p>

      <ul>
        <li>
          <strong>email addresses</strong>
        </li>
        <li>
          <strong>usernames</strong>
        </li>
        <li>
          <strong>passwords</strong>
        </li>
        <li>
          <strong>names</strong>
        </li>
      </ul>

      <p>
        <strong>Sensitive Information.</strong> We do not process sensitive
        information.
      </p>

      <p>
        <strong>Social Media Login Data.</strong> We may provide you with the
        option to register with us using your existing social media account
        details, like your Facebook, Twitter, or other social media account. If
        you choose to register in this way, we will collect the information
        described in the section called “
        <strong>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>” below.
      </p>

      <p>
        <strong>Application Data.</strong> If you use our application(s), we
        also may collect the following information if you choose to provide us
        with access or permission:
      </p>

      <ul>
        <li>
          <strong>Geolocation Information.</strong> We may request access or
          permission to track location-based information from your mobile
          device, either continuously or while you are using our mobile
          application(s), to provide certain location-based services. If you
          wish to change our access or permissions, you may do so in your
          device’s settings.
        </li>
        <li>
          <strong>Mobile Device Access.</strong> We may request access or
          permission to certain features from your mobile device, including your
          mobile device’s calendar, camera, contacts, reminders, storage, and
          other features. If you wish to change our access or permissions, you
          may do so in your device’s settings.
        </li>
        <li>
          <strong>Mobile Device Data.</strong> We automatically collect device
          information (such as your mobile device ID, model, and manufacturer),
          operating system, version information and system configuration
          information, device and application identification numbers, browser
          type and version, hardware model Internet service provider and/or
          mobile carrier, and Internet Protocol (IP) address (or proxy server).
          If you are using our application(s), we may also collect information
          about the phone network associated with your mobile device, your
          mobile device’s operating system or platform, the type of mobile
          device you use, your mobile device’s unique device ID, and information
          about the features of our application(s) you accessed.
        </li>
        <li>
          <strong>Push Notifications.</strong> We may request to send you push
          notifications regarding your account or certain features of the
          application(s). If you wish to opt out from receiving these types of
          communications, you may turn them off in your device’s settings.
        </li>
      </ul>

      <p>
        This information is primarily needed to maintain the security and
        operation of our application(s), for troubleshooting, and for our
        internal analytics and reporting purposes.
      </p>

      <p>
        <strong>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </strong>
      </p>

      <p>
        <strong>Information automatically collected</strong>
      </p>

      <p>
        <strong>In Short:</strong> Some information — such as your Internet
        Protocol (IP) address and/or browser and device characteristics — is
        collected automatically when you visit our Services.
      </p>

      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>

      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.
      </p>

      <p>
        <strong>The information we collect includes:</strong>
      </p>

      <ul>
        <li>
          <strong>Log and Usage Data.</strong> Log and usage data is
          service-related, diagnostic, usage, and performance information our
          servers automatically collect when you access or use our Services and
          which we record in log files. Depending on how you interact with us,
          this log data may include your IP address, device information, browser
          type, and settings and information about your activity in the Services
          (such as the date/time stamps associated with your usage, pages and
          files viewed, searches, and other actions you take such as which
          features you use), device event information (such as system activity,
          error reports (sometimes called “crash dumps”), and hardware
          settings).
        </li>
        <li>
          <strong>Device Data.</strong> We collect device data such as
          information about your computer, phone, tablet, or other device you
          use to access the Services. Depending on the device used, this device
          data may include information such as your IP address (or proxy
          server), device and application identification numbers, location,
          browser type, hardware model, Internet service provider and/or mobile
          carrier, operating system, and system configuration information.
        </li>
        <li>
          <strong>Location Data.</strong> We collect location data such as
          information about your device’s location, which can be either precise
          or imprecise. How much information we collect depends on the type and
          settings of the device you use to access the Services. For example, we
          may use GPS and other technologies to collect geolocation data that
          tells us your current location (based on your IP address). You can opt
          out of allowing us to collect this information either by refusing
          access to the information or by disabling your Location setting on
          your device. However, if you choose to opt out, you may not be able to
          use certain aspects of the Services.
        </li>
      </ul>

      <p>
        <strong>HOW DO WE PROCESS YOUR INFORMATION?</strong>
      </p>

      <p>
        <strong>In Short:</strong> We process your information to provide,
        improve, and administer our Services, communicate with you, for security
        and fraud prevention, and to comply with law. We may also process your
        information for other purposes with your consent.
      </p>

      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </p>

      <ul>
        <li>
          <strong>
            To facilitate account creation and authentication and otherwise
            manage user accounts.
          </strong>{" "}
          We may process your information so you can create and log in to your
          account, as well as keep your account in working order.
        </li>
        <li>
          <strong>
            To deliver and facilitate delivery of services to the user.
          </strong>{" "}
          We may process your information to provide you with the requested
          service.
        </li>
        <li>
          <strong>To enable user-to-user communications.</strong> We may process
          your information if you choose to use any of our offerings that allow
          for communication with another user.
        </li>
        <li>
          <strong>To save or protect an individual’s vital interest.</strong> We
          may process your information when necessary to save or protect an
          individual’s vital interest, such as to prevent harm.
        </li>
      </ul>

      <p>
        <strong>
          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </strong>
      </p>

      <p>
        <strong>In Short:</strong> We only process your personal information
        when we believe it is necessary and we have a valid legal reason (i.e.,
        legal basis) to do so under applicable law, like with your consent, to
        comply with laws, to provide you with services to enter into or fulfill
        our contractual obligations, to protect your rights, or to fulfill our
        legitimate business interests.
      </p>

      <p>If you are located in the EU or UK, this section applies to you.</p>

      <p>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:
      </p>

      <ul>
        <li>
          <strong>Consent.</strong> We may process your information if you have
          given us permission (i.e., consent) to use your personal information
          for a specific purpose. You can withdraw your consent at any time.{" "}
          <a href="#">Click here</a> to learn more.
        </li>
        <li>
          <strong>Performance of a Contract.</strong> We may process your
          personal information when we believe it is necessary to fulfill our
          contractual obligations to you, including providing our Services or at
          your request prior to entering into a contract with you.
        </li>
        <li>
          <strong>Legal Obligations.</strong> We may process your information
          where we believe it is necessary for compliance with our legal
          obligations, such as to cooperate with a law enforcement body or
          regulatory agency, exercise or defend our legal rights, or disclose
          your information as evidence in litigation in which we are involved.
        </li>
        <li>
          <strong>Vital Interests.</strong> We may process your information
          where we believe it is necessary to protect your vital interests or
          the vital interests of a third party, such as situations involving
          potential threats to the safety of any person.
        </li>
      </ul>

      <p>
        <strong>
          If you are located in Canada, this section applies to you.
        </strong>
      </p>

      <p>
        We may process your information if you have given us specific permission
        (i.e., express consent) to use your personal information for a specific
        purpose, or in situations where your permission can be inferred (i.e.,
        implied consent). You can withdraw your consent at any time.{" "}
        <a href="#">Click here</a> to learn more.
      </p>

      <p>
        In some exceptional cases, we may be legally permitted under applicable
        law to process your information without your consent, including, for
        example:
      </p>

      <ul>
        <li>
          If collection is clearly in the interests of an individual and consent
          cannot be obtained in a timely way
        </li>
        <li>For investigations and fraud detection and prevention</li>
        <li>For business transactions provided certain conditions are met</li>
        <li>
          If it is contained in a witness statement and the collection is
          necessary to assess, process, or settle an insurance claim
        </li>
        <li>
          For identifying injured, ill, or deceased persons and communicating
          with next of kin
        </li>
        <li>
          If we have reasonable grounds to believe an individual has been, is,
          or may be the victim of financial abuse
        </li>
        <li>
          If it is reasonable to expect collection and use with consent would
          compromise the availability or the accuracy of the information and the
          collection is reasonable for purposes related to investigating a
          breach of an agreement or a contravention of the laws of Canada or a
          province
        </li>
        <li>
          If disclosure is required to comply with a subpoena, warrant, court
          order, or rules of the court relating to the production of records
        </li>
        <li>
          If it was produced by an individual in the course of their employment,
          business, or profession and the collection is consistent with the
          purposes for which the information was produced
        </li>
        <li>
          If the collection is solely for journalistic, artistic, or literary
          purposes
        </li>
        <li>
          If the information is publicly available and is specified by the
          regulations
        </li>
      </ul>

      <p>
        <strong>
          WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </strong>
      </p>

      <p>
        <strong>In Short:</strong> We may share information in specific
        situations described in this section and/or with the following third
        parties.
      </p>

      <p>
        We may need to share your personal information in the following
        situations:
      </p>

      <ul>
        <li>
          <strong>Business Transfers.</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
        <li>
          <strong>When we use Google Maps Platform APIs.</strong> We may share
          your information with certain Google Maps Platform APIs (e.g., Google
          Maps API, Places API). To find out more about Google’s Privacy Policy,
          please refer to <a href="link_to_google_privacy_policy">this link</a>.
          We use certain Google Maps Platform APIs to retrieve certain
          information when you make location-specific requests. This includes:
          geolocation; and other similar information. A full list of what we use
          information for can be found in this section and in the previous
          section titled “HOW DO WE PROCESS YOUR INFORMATION?“. We obtain and
          store on your device (“cache”) your location. You may revoke your
          consent anytime by contacting us at the contact details provided at
          the end of this document. The Google Maps Platform APIs that we use
          store and access cookies and other information on your devices. If you
          are a user currently in the European Economic Area (EU countries,
          Iceland, Liechtenstein, and Norway) or the United Kingdom, please take
          a look at our Cookie Notice.
        </li>
        <li>
          <strong>Offer Wall.</strong> Our application(s) may display a
          third-party hosted “offer wall.” Such an offer wall allows third-party
          advertisers to offer virtual currency, gifts, or other items to users
          in return for the acceptance and completion of an advertisement offer.
          Such an offer wall may appear in our application(s) and be displayed
          to you based on certain data, such as your geographic area or
          demographic information. When you click on an offer wall, you will be
          brought to an external website belonging to other persons and will
          leave our application(s). A unique identifier, such as your user ID,
          will be shared with the offer wall provider in order to prevent fraud
          and properly credit your account with the relevant reward.
        </li>
      </ul>

      <p>
        <strong>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</strong>
      </p>

      <p>
        <strong>In Short:</strong> We are not responsible for the safety of any
        information that you share with third parties that we may link to or who
        advertise on our Services, but are not affiliated with, our Services.
      </p>

      <p>
        The Services, including our offer wall, may link to third-party
        websites, online services, or mobile applications and/or contain
        advertisements from third parties that are not affiliated with us and
        which may link to other websites, services, or applications.
        Accordingly, we do not make any guarantee regarding any such third
        parties, and we will not be liable for any loss or damage caused by the
        use of such third-party websites, services, or applications. The
        inclusion of a link towards a third-party website, service, or
        application does not imply an endorsement by us. We cannot guarantee the
        safety and privacy of data you provide to any third parties. Any data
        collected by third parties is not covered by this privacy notice. We are
        not responsible for the content or privacy and security practices and
        policies of any third parties, including other websites, services, or
        applications that may be linked to or from the Services. You should
        review the policies of such third parties and contact them directly to
        respond to your questions.
      </p>

      <p>
        <strong>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
      </p>

      <p>
        <strong>In Short:</strong> We may use cookies and other tracking
        technologies to collect and store your information.
      </p>

      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </p>

      <p>
        <strong>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
      </p>

      <p>
        <strong>In Short:</strong> If you choose to register or log in to our
        services using a social media account, we may have access to certain
        information about you.
      </p>

      <p>
        Our Services offer you the ability to register and log in using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, and profile picture, as well as other information you choose to
        make public on such a social media platform.
      </p>

      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Services. Please note that we do not control, and are
        not responsible for, other uses of your personal information by your
        third-party social media provider. We recommend that you review their
        privacy notice to understand how they collect, use, and share your
        personal information, and how you can set your privacy preferences on
        their sites and apps.
      </p>

      <p>
        <strong>HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
      </p>

      <p>
        <strong>In Short:</strong> We keep your information for as long as
        necessary to fulfill the purposes outlined in this privacy notice unless
        otherwise required by law.
      </p>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
      </p>

      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>

      <p>
        <strong>HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
      </p>

      <p>
        <strong>In Short:</strong> We aim to protect your personal information
        through a system of organizational and technical security measures.
      </p>

      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>

      <p>
        <strong>WHAT ARE YOUR PRIVACY RIGHTS?</strong>
      </p>

      <p>
        <strong>In Short:</strong> In some regions, such as the European
        Economic Area (EEA), United Kingdom (UK), and Canada, you have rights
        that allow you greater access to and control over your personal
        information. You may review, change, or terminate your account at any
        time.
      </p>

      <p>
        In some regions (like the EEA, UK, and Canada), you have certain rights
        under applicable data protection laws. These may include the right (i)
        to request access and obtain a copy of your personal information, (ii)
        to request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us by using the contact details provided in the section “HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?“ below.
      </p>

      <p>
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>

      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details{" "}
        <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          here
        </a>
        .
      </p>

      <p>
        If you are located in Switzerland, the contact details for the data
        protection authorities are available{" "}
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">here</a>.
      </p>

      <p>
        <strong>Withdrawing your consent:</strong> If we are relying on your
        consent to process your personal information, which may be express
        and/or implied consent depending on the applicable law, you have the
        right to withdraw your consent at any time. You can withdraw your
        consent at any time by contacting us by using the contact details
        provided in the section “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?“
        below.
      </p>

      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>

      <p>
        <strong>Opting out of marketing and promotional communications:</strong>{" "}
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        or by contacting us using the details provided in the section “HOW CAN
        YOU CONTACT US ABOUT THIS NOTICE?“ below. You will then be removed from
        the marketing lists. However, we may still communicate with you — for
        example, to send you service-related messages that are necessary for the
        administration and use of your account, to respond to service requests,
        or for other non-marketing purposes.
      </p>

      <p>
        <strong>Account Information</strong>
      </p>

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>

      <ul>
        <li>Log in to your account settings and update your user account.</li>
      </ul>

      <p>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </p>

      <p>
        Cookies and similar technologies: Most Web browsers are set to accept
        cookies by default. If you prefer, you can usually choose to set your
        browser to remove cookies and to reject cookies. If you choose to remove
        cookies or reject cookies, this could affect certain features or
        services of our Services. To opt out of interest-based advertising by
        advertisers on our Services visit{" "}
        <a href="http://www.aboutads.info/choices/">
          http://www.aboutads.info/choices/
        </a>
        .
      </p>

      <p>
        If you have questions or comments about your privacy rights, you may
        email us at <a href="mailto:tnpnews9@gmail.com">tnpnews9@gmail.com</a>.
      </p>

      <p>CONTROLS FOR DO-NOT-TRACK FEATURES</p>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track (“DNT”) feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>

      <p>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
      <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
      </p>

      <p>
        California Civil Code Section 1798.83, also known as the “Shine The
        Light” law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>

      <p>
        <strong>CCPA Privacy Notice</strong>
      </p>

      <p>The California Code of Regulations defines a “resident” as:</p>

      <ol>
        <li>
          every individual who is in the State of California for other than a
          temporary or transitory purpose and
        </li>
        <li>
          every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
        </li>
      </ol>

      <p>All other individuals are defined as “non-residents.”</p>

      <p>
        If this definition of “resident” applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>

      <p>
        <strong>What categories of personal information do we collect?</strong>
      </p>

      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>

      <table>
        <thead>
          <tr>
            <th>Category</th>
            <th>Examples</th>
            <th>Collected</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A. Identifiers</td>
            <td>
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address, and
              account name
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California
              Customer Records statute
            </td>
            <td>
              Name, contact information, education, employment, employment
              history, and financial information
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              C. Protected classification characteristics under California or
              federal law
            </td>
            <td>Gender and date of birth</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>D. Commercial information</td>
            <td>
              Transaction information, purchase history, financial details, and
              payment information
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>E. Biometric information</td>
            <td>Fingerprints and voiceprints</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity</td>
            <td>
              Browsing history, search history, online behavior, interest data,
              and interactions with our and other websites, applications,
              systems, and advertisements
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>G. Geolocation data</td>
            <td>Device location</td>
            <td>YES</td>
          </tr>
          <tr>
            <td>
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td>
              Images and audio, video, or call recordings created in connection
              with our business activities
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information</td>
            <td>
              Business contact details in order to provide you our Services at a
              business level or job title, work history, and professional
              qualifications if you apply for a job with us
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>J. Education Information</td>
            <td>Student records and directory information</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information</td>
            <td>
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </td>
            <td>NO</td>
          </tr>
        </tbody>
      </table>

      <p>
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of:
      </p>

      <ul>
        <li>Receiving help through our customer support channels;</li>
        <li>Participation in customer surveys or contests; and</li>
        <li>
          Facilitation in the delivery of our Services and to respond to your
          inquiries.
        </li>
      </ul>

      <p>
        <strong>How do we use and share your personal information?</strong>
      </p>

      <p>
        More information about our data collection and sharing practices can be
        found in this privacy notice.
      </p>

      <p>
        You may contact us by email at{" "}
        <a href="mailto:tnpnews9@gmail.com">tnpnews9@gmail.com</a>, or by
        referring to the contact details at the bottom of this document.
      </p>

      <p>
        If you are using an authorized agent to exercise your right to opt out
        we may deny a request if the authorized agent does not submit proof that
        they have been validly authorized to act on your behalf.
      </p>

      <p>
        <strong>Will your information be shared with anyone else?</strong>
      </p>

      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf.
      </p>

      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be “selling” of your personal
        information.
      </p>

      <p>
        TNP News has not disclosed or sold any personal information to third
        parties for a business or commercial purpose in the preceding twelve
        (12) months. TNP News will not sell personal information in the future
        belonging to website visitors, users, and other consumers.
      </p>

      <p>
        <strong>Your rights with respect to your personal data</strong>
      </p>

      <p>
        <strong>
          Right to request deletion of the data — Request to delete
        </strong>
      </p>

      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>

      <p>
        <strong>Right to be informed — Request to know</strong>
      </p>

      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell your personal information to third parties;</li>
        <li>
          the categories of personal information that we sold or disclosed for a
          business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold or disclosed for a business purpose; and
        </li>
        <li>
          the business or commercial purpose for collecting or selling personal
          information.
        </li>
      </ul>
      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>

      <p>
        <strong>
          Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
          Rights
        </strong>
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>

      <p>
        <strong>Verification process</strong>
      </p>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <p>
        <strong>Other privacy rights</strong>
      </p>
      <ul>
        <li>You may object to the processing of your personal information.</li>
        <li>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </li>
        <li>
          You can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>
        <li>
          You may request to opt out from future selling of your personal
          information to third parties. Upon receiving an opt-out request, we
          will act upon the request as soon as feasibly possible, but no later
          than fifteen (15) days from the date of the request submission.
        </li>
        <li>
          To exercise these rights, you can contact us by email at{" "}
          <a href="mailto:tnpnews9@gmail.com">tnpnews9@gmail.com</a>, or by
          referring to the contact details at the bottom of this document. If
          you have a complaint about how we handle your data, we would like to
          hear from you.
        </li>
      </ul>

      <p>
        <strong>DO WE MAKE UPDATES TO THIS NOTICE?</strong>
      </p>
      <p>
        <strong>In Short:</strong> Yes, we will update this notice as necessary
        to stay compliant with relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated “Revised” date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>

      <p>
        <strong>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
      </p>
      <p>
        If you have questions or comments about this notice, you may email us at{" "}
        <a href="mailto:tnpnews9@gmail.com">tnpnews9@gmail.com</a> or by post to:
      </p>

      <p>
        <strong>TNP News</strong>
      </p>
      <p>Siegfriedstr. 19</p>
      <p>Berlin, Berlin 13156</p>
      <p>Germany</p>

      <p>
        <strong>
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </strong>
      </p>
      <p>
        You have the right to request access to the personal information we
        collect from you, change that information, or delete it. To request to
        review, update, or delete your personal information, please visit:{" "}
        <a href="https://tnpnews.in/">https://tnpnews.in/</a>.
      </p>

      <p>
        <strong>
          ACCEPTABLE USE – WHAT CAN YOU DO AND WHEN DO WE STEP IN?
        </strong>
      </p>
      <p>
        This acceptable use policy sets out a list of acceptable and
        unacceptable conduct for our Services. If we believe that a violation of
        the policy is deliberate, repeated or presents a credible risk of harm
        to other users, our customers, the Services or any third parties, we may
        suspend or terminate your access.
      </p>

      <p>
        <strong>What you may do within the application:</strong>
      </p>
      <ul>
        <li>
          comply with all User Terms of Service, including the terms of this
          Acceptable Use Policy;
        </li>
        <li>
          comply with all applicable laws and governmental regulations,
          including, but not limited to, all intellectual property, data,
          privacy and export control laws, and regulations promulgated by any
          government agencies, including, but not limited to, the US Securities
          and Exchange Commission and any rules of any national and other
          securities exchanges;
        </li>
        <li>
          upload and disseminate only Customer Data to which the Customer owns
          all required rights under law and under contractual and fiduciary
          relationships (such as proprietary and confidential information learnt
          or disclosed as part of employment relationships or under
          non-disclosure agreements) and do so only in a manner consistent with
          applicable law;
        </li>
        <li>
          use commercially reasonable efforts to prevent unauthorized access to
          or use of the Services;
        </li>
        <li>keep passwords and all other login information confidential;</li>
        <li>
          monitor and control all activity conducted through your account in
          connection with the Services;
        </li>
        <li>
          promptly notify us if you become aware of or reasonably suspect any
          illegal or unauthorized activity or a security breach involving your
          accounts or teams, including any loss, theft or unauthorized
          disclosure or use of a username, password or account; and
        </li>
        <li>
          comply in all respects with all applicable terms of the third-party
          applications, including any that the Customer elects to integrate with
          the Services that you access or subscribe to in connection with the
          Services.
        </li>
      </ul>

      <p>
        <strong>
          What you should not do and would allow us to remove content or
          restrict access:
        </strong>
      </p>
      <ul>
        <li>
          permit any third party that is not an Authorized User to access or use
          a username or password for the Services;
        </li>
        <li>
          share, transfer or otherwise provide access to an account designated
          for you to another person;
        </li>
        <li>
          use the Services to store or transmit any Customer Data that may
          infringe upon or misappropriate someone else’s trademark, copyright or
          other intellectual property, or that may be tortious or unlawful;
        </li>
        <li>
          upload to or transmit from the Services any data, file, software or
          link that contains or redirects to a virus, Trojan horse, worm or
          other harmful component, or a technology that unlawfully accesses or
          downloads content or information stored within the Services or on the
          hardware of Slack or any third party;
        </li>
        <li>
          attempt to reverse engineer, decompile, hack, disable, interfere with,
          disassemble, modify, copy, translate or disrupt the features,
          functionality, integrity or performance of the Services (including any
          mechanism used to restrict or control the functionality of the
          Services), any third-party use of the Services or any third-party data
          contained therein (except to the extent such restrictions are
          prohibited by applicable law);
        </li>
        <li>
          attempt to gain unauthorized access to the Services or related systems
          or networks or to defeat, avoid, bypass, remove, deactivate or
          otherwise circumvent any software protection or monitoring mechanisms
          of the Services;
        </li>
        <li>
          access the Services in order to build a similar or competitive product
          or service or copy any ideas, features, functions or graphics
          pertaining to the Services;
        </li>
        <li>
          use the Services in any manner that may harm minors or that interacts
          with or targets people under the age of thirteen;
        </li>
        <li>
          engage in activity that incites or encourages violence or hatred
          against individuals or groups;
        </li>
        <li>
          use the Services to provide material support or resources (or to
          conceal or disguise the nature, location, source or ownership of
          material support or resources) to any organization(s) designated by
          the United States government as a foreign terrorist organization
          pursuant to Section 219 of the US Immigration and Nationality Act or
          other laws and regulations concerning national security, defense or
          terrorism;
        </li>
        <li>
          access, search or create accounts for the Services by any means other
          than our publicly supported interfaces (e.g. ‘scraping’ or creating
          accounts in bulk);
        </li>
        <li>
          send unsolicited communications, promotions or advertisements, or
          spam;
        </li>
        <li>place any advertisements within a TNP Reporter App client;</li>
        <li>
          send altered, deceptive or false source-identifying information,
          including ‘spoofing’ or ‘phishing’;
        </li>
        <li>
          abuse referrals or promotions to get more credits than deserved;
        </li>
      </ul>

      <p>
        <strong>
          Furthermore, the following content may lead to the removal of content
          or restricted access:
        </strong>
      </p>
      <ul>
        <li>Content that violates third-party intellectual property rights</li>
        <li>Content that is offensive, obscene or discriminatory</li>
        <li>Content that could cause harm (including damaging computers)</li>
        <li>Content that enables or encourages people to break the law</li>
        <li>Repeatedly reposting the same content</li>
        <li>Reposting content that has already been removed</li>
        <li>
          Content that includes somebody’s personal details or private
          information, particularly that of third parties
        </li>
        <li>Content that contains advertising or promotional material</li>
        <li>
          Posting material under a new account after being suspended or banned
          from using the site
        </li>
      </ul>

      <p>
        <strong>
          When not compliant with these terms we may take the following steps:
        </strong>
      </p>
      <ul>
        <li>
          <strong>Altering or Deleting Content:</strong> We may take steps to
          delete or alter content that is incorrect or contrary to the terms of
          this policy. This includes all user submissions and any other content,
          listings, posts, comments and messages.
        </li>
        <li>
          <strong>Warning:</strong> We may issue a first warning to a user in
          cases of lesser breaches of, or lack of compliance with the Terms.
        </li>
        <li>
          <strong>Account Deletion / User Ban:</strong> If a user breaches the
          Terms or fails to comply with the Terms/policies, we also have the
          option to permanently delete a users account or block the users access
          to the application.
        </li>
      </ul>
    </div>
  );
}
