import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	users: {},
};

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: {
		UserDetailSuccess: (state, action) => {
			let Users = {...state.users};
			Users = [...Users, ...action.payload.users]
		}
	},
});

export const users = state => state.users;
export default usersSlice.reducer;
