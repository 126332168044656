import { useEffect, useState } from "react";
import Input from "./Input";
import "./CreateProfileForm.scss";
import userIcon from "./../../assets/icons/user-icon.png";
import { useNavigate } from "react-router-dom";
import signUp from "../../logic/auth/signUp";
import Network from "../../logic/api";
import { CreateUser } from "../../logic/store/actions/actionConstants";
import { useDispatch, useSelector } from "react-redux";
import store from "../../logic/store/store";
import {
  UpdateUserProfile,
  UploadUserPicture,
  GetUserProfile,
} from "../../logic/store/actions/actionConstants";

import {
  DatePicker,
  SelectPicker,
  Loader,
  Placeholder,
  Table,
  Button,
  ButtonToolbar,
} from "rsuite";

export default function CreateProfile(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [fullNameInput, setFullNameInput] = useState("");
  const [completeAddressInput, setCompleteAddressInput] = useState("");

  const [locations, setLocations] = useState([]);
  const [activeState, setState] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [activeDist, setDist] = useState(null);
  const [distData, setDistData] = useState([]);
  const [gender, setGender] = useState(null);
  const [genderData, setGenderData] = useState([
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]);

  const [activePincode, setPincode] = useState(null);
  const [pincodeData, setPincodeData] = useState([]);

  const [activeVillage, setVillage] = useState(null);
  const [villageData, setVillageData] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const [educationInput, setEducationInput] = useState(null);
  const [educationData, setEducationData] = useState([
    { value: "Basic Education", label: "Basic Education" },
    {
      value: "10th Standard (High School)",
      label: "10th Standard (High School)",
    },
    {
      value: "12th Standard (Senior Secondary School)",
      label: "12th Standard (Senior Secondary School)",
    },
    { value: "Graduate", label: "Graduate" },
    { value: "Post-Graduate", label: "Post-Graduate" },
  ]);

  const [activeDate, setDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (user.name === null) {
        setLoading(true);
        try {
          const result = await dispatch(
            Network.networkCall({
              action: GetUserProfile,
              data: {
                user_id: user.user_id,
              },
            })
          );

          if (result.success) {
            setLoading(false);
            console.log("Successfully retrieved user profile.", result);
          }
        } catch (error) {
          setLoading(false);
          console.log("Failed to get user profile.", error);
        }
      }
    };
    fetchData();
    getLocation();
  }, []);

  function detectFullNameInput(e) {
    setFullNameInput(e.target.value);
  }

  const handleDateChange = (date) => setDate(date);

  function handleGenderChange(value) {
    setGender(value);
  }

  function detectEducationInput(value) {
    setEducationInput(value);
  }

  function detectCompleteAddressInput(e) {
    setCompleteAddressInput(e.target.value);
  }

  async function getLocation() {
    try {
      const response = await fetch(
        "https://tnp-utils.s3.ap-south-1.amazonaws.com/location.json"
      );
      const locations = await response.json();
      let locData = [];
      let statesArray = [];
      let temp = [];
      locations.forEach((location) => {
        Object.keys(location).forEach((state, i) => {
          statesArray.push(state);
          location[state].forEach((distObj, j) => {
            Object.keys(distObj).forEach((dist, k) => {
              distObj[dist].forEach((pinObj) => {
                Object.keys(pinObj).forEach((pin) => {
                  pinObj[pin].forEach((village) => {
                    locData.push({
                      state: state,
                      dist: dist,
                      pin: pin,
                      village: village,
                    });
                  });
                });
              });
            });
          });
        });
      });
      let stateDataObj = [...new Set(locData.map((item) => item.state))].map(
        (item) => ({ label: item, value: item })
      );
      setLocations(locData);
      setStateData(stateDataObj);
    } catch (err) {
      console.log(err);
    }
  }

  const handleStateChange = (state) => {
    let data = locations.filter((l) => state.includes(l.state));
    let distData = [...new Set(data.map((item) => item.dist))].map((item) => ({
      label: item,
      value: item,
    }));
    setState(state);
    setDistData(distData);
  };
  const handleDistChange = (dist) => {
    let data = locations
      .filter((l) => activeState.includes(l.state))
      .filter((d) => dist.includes(d.dist));
    let pincodeData = [...new Set(data.map((item) => item.pin))].map(
      (item) => ({ label: item, value: item })
    );
    setDist(dist);
    setPincodeData(pincodeData);
  };

  const handlePincodeChange = (pin) => {
    let data = locations
      .filter((l) => activeState.includes(l.state))
      .filter((d) => activeDist.includes(d.dist))
      .filter((p) => pin.includes(p.pin));
    let villageData = [...new Set(data.map((item) => item.village))].map(
      (item) => ({ label: item, value: item })
    );
    setPincode(pin);
    setVillageData(villageData);
  };

  const handleVillageChange = (village) => {
    setVillage(village);
  };

  function uploadImage(base64, mime, fileName) {
    setLoading(true);
    return dispatch(
      Network.networkCall({
        action: UploadUserPicture,
        data: {
          user_id: user.user_id,
          creation_ts: user.creation_ts,
          key: fileName,
          mime: mime,
          image: base64,
        },
      })
    )
      .then(function (result) {
        setLoading(false);
        if (result.success) {
          setSelectedImage(result.data.imageURL);
          console.log(
            "Successfully Upload User Picture.",
            result.data.imageURL
          );
        } else {
          console.log(result.data.imageURL);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Failed to Upload User Picture." + error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    return dispatch(
      Network.networkCall({
        action: UpdateUserProfile,
        data: {
          user_id: user.user_id,
          creation_ts: user.creation_ts,
          name: fullNameInput,
          block: activeVillage,
          district: activeDist,
          state: activeState,
          gender: gender,
          pincode: activePincode,
          address: completeAddressInput,
          education: educationInput,
          dob: activeDate,
          img: selectedImage,
        },
      })
    )
      .then(function (result) {
        if (result.success) {
          setLoading(false);
          navigate("/audition");
          console.log("Successfully Update User Profile.", result);
        } else {
          setLoading(false);
          console.log(result);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Failed to Update User Profil." + error);
      });
  };

  useEffect(() => {
    if (
      fullNameInput !== "" &&
      completeAddressInput !== "" &&
      activeDate &&
      activeDist &&
      activePincode &&
      activeState &&
      activeVillage &&
      gender &&
      educationInput
    ) {
      // document.getElementById("registerSubmit").classList.add("submitProg50");
    }
  });

  const handleImageChange = async (event) => {
    const file = await event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        const mime = reader.result.split(";")[0].split(":")[1];
        const fileName = file.name;
        uploadImage(base64, mime, fileName);
      };
      reader.readAsDataURL(file);
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="CreateProfileFormContainer">
      {loading &&  <Loader backdrop center size="md" content="Loading..." vertical />}
          <div className="image-picker-container">
            <img
              src={selectedImage ? selectedImage : userIcon}
              alt="Selected"
              className="preview-image"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              id="fileInput"
              className="file-input"
            />
            <button onClick={handleImageChange} className="custom-file-input">
              Choose Image
            </button>
          </div>

          <form id="registerForm" onSubmit={handleSubmit}>
            <Input
              type="text"
              inputId="fullName"
              required="required"
              labelId="registefullNameLabel"
              labelString="Full Name"
              detectInput={detectFullNameInput}
            />

            <DatePicker
              placeholder="Select Date of Birth"
              onChange={handleDateChange}
              style={{ width: "100%" }}
            />

            <SelectPicker
              searchable={false}
              cleanable={false}
              data={genderData}
              label="Gender"
              onChange={handleGenderChange}
              style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
            />

            <SelectPicker
              searchable={false}
              cleanable={false}
              data={educationData}
              label="Education"
              onChange={detectEducationInput}
              style={{ width: "100%", marginBottom: 15 }}
            />

            <Input
              type="text"
              inputId="CompleteAddress"
              required="required"
              labelId="registeCompleteAddressLabel"
              labelString="Complete Address"
              detectInput={detectCompleteAddressInput}
            />

            <SelectPicker
              searchable={false}
              cleanable={false}
              data={stateData}
              label="State"
              onChange={handleStateChange}
              style={{ width: "100%" }}
            />
            <SelectPicker
              searchable={false}
              cleanable={false}
              data={distData}
              label="District"
              onChange={handleDistChange}
              style={{ width: "100%", marginTop: 15 }}
            />
            <SelectPicker
              searchable={false}
              cleanable={false}
              data={pincodeData}
              label="Pincode"
              onChange={handlePincodeChange}
              style={{ width: "100%", marginTop: 15 }}
            />
            <SelectPicker
              searchable={false}
              cleanable={false}
              data={villageData}
              label="Block"
              onChange={handleVillageChange}
              style={{ width: "100%", marginTop: 15 }}
            />
          </form>

          <div className="checkboxContainer">
            <button type="submit" form="registerForm" id="registerSubmit">
              Register
            </button>
          </div>
    </div>
  );
}
