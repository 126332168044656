import { Modal,Button, Placeholder } from 'rsuite';
import './updateRole.scss';
import { useState, useMemo, useEffect } from "react";
import { Checkbox, CheckboxGroup, RadioGroup, Radio, CheckPicker } from 'rsuite';
import { Roles, Levels } from "./../../routes/Paths";

export default function UpdateRole(props) {
    const [activeRole, setRole] = useState([props.user.roleid]);
    const [activeLevel, setLevel] = useState(props.user.levelid);
    const [locations, setLocations] = useState([]);
    const [loader, setLoader] = useState(true);
    const [activeState, setState] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [activeDist, setDist] = useState([]);
    const [distData, setDistData] = useState([]);
    const [activeVillage, setVillage] = useState([]);
    const [villageData, setVillageData] = useState([]);

    const handleClose = () => props.modalClose();
    const handleConfirm = () => { props.updateRole(props.user); }
    const handleChangeRoles = role => { props.user.roleid = role; setRole(role); }
    const handleChangeLevel = level => { props.user.levelid = level; setLevel(level); }

    // Add default value on page load
    useEffect(() => {
        getLocation();
        setLevel(props.user.levelid);
        setRole(props.user.roleid);
    }, [props]);

    async function getLocation() {
        try {
            const response = await fetch('https://tnp-utils.s3.ap-south-1.amazonaws.com/location.json');
            const locations = await response.json();
            let locData = [];
            let statesArray = [];
            let temp = [];
            locations.forEach((location) => {
                Object.keys(location).forEach((state, i) => {
                    statesArray.push(state);
                    location[state].forEach((distObj, j) => {
                        Object.keys(distObj).forEach((dist, k) => {
                            distObj[dist].forEach(pinObj => {
                                Object.keys(pinObj).forEach(pin => {
                                    pinObj[pin].forEach(village => { locData.push({ "state": state, "dist": dist, "pin": pin, "village": village }); });
                                })
                            })
                        })
                    });
                });
            });
            let stateDataObj = [...new Set(locData.map(item => item.state))].map(item => ({ label: item, value: item }));
            setLocations(locData);
            setStateData(stateDataObj);
        } catch (err) {
            console.log(err);
        }
    }

    const handleStateChange = state => {
        let data = locations.filter(l => state.includes(l.state));
        let distData = [...new Set(data.map(item => item.dist))].map(item => ({ label: item, value: item }));
        setState(state);
        setDistData(distData);
    }
    const handleDistChange = dist => {
        let data = locations.filter(l => activeState.includes(l.state))
            .filter(d => dist.includes(d.dist))
        let villageData = [...new Set(data.map(item => item.village))].map(item => ({ label: item, value: item }));
        setDist(dist);
        setVillageData(villageData);
    }

    const handleVillageChange = village => {
        setVillage(village);
    }


  return (
   
    <Modal size="sm" open={props.open} onClose={handleClose}>
          <Modal.Header>
              <Modal.Title><strong className="text-danger">Assign Role to</strong></Modal.Title>
        </Modal.Header>
          <Modal.Body>
              <div className="model-body">
            <div className='profile'>
                <div className='name col-4'>
                    <div className='text-secondary'>Name</div>
                    <div>{props.user.name}</div>
                  </div>
                  <div className='name col-4'>
                      <div className='text-secondary'>Mobile No</div>
                      <div>{props.user.phone_no}</div>
                  </div>
                <div className='name col-4'>
                    <div className='text-secondary'>Email ID</div>
                    <div>{props.user.user_id}</div>
                </div>
            </div>
            <div className='profile'>
                <div className='name'>
                      <div className='text-dark'><strong>Select Role</strong></div>
                      <div>
                          <CheckboxGroup
                              name="checkboxList"
                              value={activeRole}
                              inline
                              onChange={handleChangeRoles}
                          >
                              {Roles.map(item => (<Checkbox className={'role_'+item.id} value={item.id}>{item.role}</Checkbox>))}
                          </CheckboxGroup>
                          </div>
                </div>
              </div>

              <div className='profile'>
                  <div className='name'>
                      <div className='text-dark'><strong>Select Level{activeLevel}</strong></div>
                      <div>
                          <RadioGroup inline name="radio-name" value={activeLevel} onChange={handleChangeLevel}>
                              {Levels.map(item => (<Radio value={item.id}>{item.level}</Radio>))}                       
                          </RadioGroup>
                      </div>
                  </div>
              </div>
              {activeLevel != 4 && (
                  <div className='profile'>
                      <div className='name'>
                          <div className='text-dark'><strong>Select Location (for level)</strong></div>
                          <div className="row">
                              {(activeLevel == 1 || activeLevel == 2 || activeLevel == 3) && (
                                  <div className='col-4'>
                                      <CheckPicker
                                          data={stateData}
                                          label="State"
                                          onChange={handleStateChange}
                                          style={{ width: 224 }} />
                                  </div>
                              )
                              }
                              {
                                  (activeLevel == 2 || activeLevel == 1) && (
                                      <div className='col-4'>
                                          <CheckPicker
                                              data={distData}
                                              label="District"
                                              onChange={handleDistChange}
                                              style={{ width: 224 }} />
                                      </div>
                                  )
                              }
                              {
                                  (activeLevel == 1) && (
                                      <div className='col-4'>
                                          <CheckPicker
                                              data={villageData}
                                              label="Block"
                                              onChange={handleVillageChange}
                                              style={{ width: 224 }} />
                                      </div>
                                  )
                              }
                          </div>
                      </div>
                  </div>
              )}
             
              </div>
          
         
        </Modal.Body>
          <Modal.Footer>
              <Button onClick={handleConfirm} className="btn btn-default">Confirm</Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
         
        </Modal.Footer>
    </Modal>
    
  );
}
