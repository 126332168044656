import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import store from "./../store/store";

const CognitoRefreshToken =
  require("amazon-cognito-identity-js").CognitoRefreshToken;

var poolData = {
  UserPoolId: process.env.REACT_APP_UserPoolId,
  ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

async function refresh(username, refreshToken) {
  let idToken = null;
  var userData = {
    Username: username,
    Pool: userPool,
  };

  var token = new CognitoRefreshToken({ RefreshToken: refreshToken });

  var cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) =>
    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        console.log(err);
        reject('expiredRefreshToken');
      } else {
        store.dispatch({
          type: "AuthSuccess",
          payload: {
            idToken: session.getIdToken().getJwtToken(),
            accessToken: session.getAccessToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
            userId: username
          },
        });
        resolve(session.getIdToken().getJwtToken());
      }
    })
  );
}

export default refresh;
