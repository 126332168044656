import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import store from "./../store/store";

var poolData = {
  UserPoolId: process.env.REACT_APP_UserPoolId,
  ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

async function loginUser(username, password) {
  var authenticationData = {
    Username: username,
    Password: password,
  };

  var authenticationDetails = new AuthenticationDetails(authenticationData);

  var userData = {
    Username: username,
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        store.dispatch({
          type: "AuthSuccess",
          payload: {
            idToken: result.getIdToken().getJwtToken(),
            accessToken: result.getAccessToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
            userId: username
          },
        });
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        console.log(userAttributes);
      },
    })
  );
}

export default loginUser;
