export const CreateUser = 'CreateUser';
export const GetUsers = 'GetUsers';
export const UpdateUserRoles = 'UpdateUserRoles';
export const UpdateUserProfile = 'UpdateUserProfile';
export const ResendOtp = 'ResendOtp';
export const ValidatePhone = 'ValidatePhone';
export const GetUserProfile = 'GetUserProfile';
export const ApproveNews = 'ApproveNews';
export const ApproveExpense = 'ApproveExpense';
export const UploadUserPicture = 'UploadUserPicture';
export const ValidateEmail = 'ValidateEmail';
export const UpdatePaymentStatus = 'UpdatePaymentStatus';
export const UpdateAuditionData = 'UpdateAuditionData';