import axios from "axios";
import store from "../store/store";
import refresh from "./../auth/refresh";
import Network from "../../logic/api";

const axiosNoAuth = axios.create({
  headers: {
    "X-Api-Key": process.env.REACT_APP_API_GATEWAY_KEY,
  },
});

const axiosAuth = axios.create({
  headers: {
    "Content-Type": "application/json",
  }
});

axiosAuth.interceptors.request.use((config) => {
  if (!config.headers["Authorization"]) {
    config.headers["Authorization"] = store.getState().auth.idToken;
  }
  return config;
});

axiosAuth.interceptors.response.use(
	response => response,
	async (err) => {
		const originalRequest = err?.config;
		console.log('Original Request: ', originalRequest);
		if (err?.response?.status === 401 && !originalRequest?.sent) {
			originalRequest.sent = true;
			let authData = store.getState().auth;
			const idToken = await refresh(authData.userId, authData.refreshToken);
			originalRequest.headers["Authorization"] = idToken;
			return axiosAuth(originalRequest);
		}
		console.log('Got the Status: ', err.response.status);
		return err;
	},
);

export { axiosNoAuth, axiosAuth };
