import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	idToken: null,
	refreshToken: null,
	accessToken: null,
	userId: null,
	isLoading: false,
	isError: false
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: {
		AuthSuccess: (state, action) => {
			state.idToken = action.payload.idToken;
			state.accessToken = action.payload.accessToken;
			state.refreshToken = action.payload.refreshToken;
			state.userId = action.payload.userId;
		},
		AuthCleanUp: (state, action) => {
			return initialState;
		  },
	},
});

export const auth = state => state.auth;
export default authSlice.reducer;
