import { Modal, Button, Placeholder } from "rsuite";
import "./ExpenseComment.scss";
import { useState, useMemo, useEffect } from "react";
import { Roles, Levels } from "./../../routes/Paths";

export default function UpdateAuditionDate(props) {
  const handleClose = () => props.modalClose();
  const handleConfirm = () => {
    props.submit();
  };

  const dateObject = new Date(props.date);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDateTime = dateObject.toLocaleString('en-US', options);

  return (
    <Modal size="sm" open={props.open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <strong className="text-danger">
             Please confirm your selected date, {formattedDateTime}.
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="model-body">
          <div className="profile">
            <div className="name col-4">
              <div className="text-secondary">Name</div>
              <div>{props.user.name}</div>
            </div>
            <div className="name col-4">
              <div className="text-secondary">Mobile No</div>
              <div>{props.user.phone_number}</div>
            </div>
            <div className="name col-4">
              <div className="text-secondary">Email ID</div>
              <div>{props.user.user_id}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={handleConfirm}
          className="btn btn-default"
        >
          Submit
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
