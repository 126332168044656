import { Mobile, Tablet, Desktop } from '../styles/Responsive';
import RegisterFormPersonal from '../components/forms/RegisterFormPersonal';
import ImgSlider from '../components/imgSlider/ImgSlider';
import './register.scss';

export default function RegisterPersonal(props) {

  console.log('Config : ' + JSON.stringify(process.env));
  return (
    <>
      <Mobile>
        <div className="m-loginContainer">
          <RegisterFormPersonal getToken={props.getToken} />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-loginContainer">
          <div className="t-loginContent__grid">
            <RegisterFormPersonal getToken={props.getToken} />
            <ImgSlider />
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="loginContainer">
          <ImgSlider />
          <RegisterFormPersonal getToken={props.getToken} />
        </div>
      </Desktop>
    </>
  );
}
