import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	expense: [],
};

export const expenseSlice = createSlice({
	name: 'expense',
	initialState,
	reducers: {},
	extraReducers: {
		ApproveExpenseSuccess: (state, action) => {
			let Expense = {...state.expense};
			let ExpenseId = action.payload.expense_id;
			Expense[ExpenseId].approved = true;
		},
        ApproveExpenseFailure: (state, action) => {
			let Expense = {...state.expense};
			let ExpenseId = action.payload.expense_id;
			Expense[ExpenseId].approved = false;
		}
	},
});

export const expense = state => state.expense;
export default expenseSlice.reducer;