import { useEffect, useState } from "react";
import Input from "./Input";
import Checkbox from "./Checkbox";
import Button from "../Button";
import "./forgetPasswordForm.scss";
import backIcon from "./../../assets/icons/back-arraw.svg";
import { useNavigate } from "react-router-dom";
import sendToken from "../../logic/auth/sendToken";
import store from "../../logic/store/store";

export default function RegisterForm(props) {
  let navigate = useNavigate();
  // styling submit button based on form input
  const [emailInput, setEmailInput] = useState(false);
  const [passwordInput, setPasswordInput] = useState(false);
  const [loading, setLoading] = useState(false);

  function detectEmailInput(e) {
    setEmailInput(e.target.value);
  }
  function detectPasswordInput(e) {
    setPasswordInput(e.target.value);
  }
  useEffect(() => {
    console.log("running conditional");
    console.log("emailInput", emailInput);
    console.log("passwordInput", passwordInput);
    if (emailInput !== "" && passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg100");
    } else if (emailInput !== "" || passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    } else {
      document.getElementById("loginSubmit").classList.remove("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    sendToken(emailInput)
      .then((res) => {
        setLoading(false);
        navigate("/forget-password2");
        store.dispatch({
          type: "AuthFailure",
          payload: {
            user_id: emailInput,
            password: null,
          },
        });
        console.log("this is the response of sendToken", res);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Failed to send Confirmation code.", error);
      });
  };

  return (
    <div className="loginFormContainer">
      <div className="reg-head">
        <div className="back">
          <img
            onClick={() => {
              navigate("/auth/login");
            }}
            src={backIcon}
            alt="Back"
          />
        </div>
        <div className="heade-title">
          <h2>TNPnews employees Forget Password.</h2>
          <p>
            Please enter your registrated Email. We will send you an email with
            a confirmation code.
          </p>
        </div>
      </div>

      <br />
      <form id="loginForm" onSubmit={handleSubmit}>
        {/* <Input
          type="number"
          inputId="mobile"
          required="required"
          labelId="loginMobileLabel"
          labelString="Mobile Number"
          detectInput={detectEmailInput}
        /> */}
        <Input
          type="email"
          inputId="email"
          required="required"
          labelId="loginEmailLabel"
          labelString="E-MAIL ADDRESS"
          detectInput={detectEmailInput}
        />
      </form>

      <div className="checkboxContainer">
        <button type="submit" form="loginForm" id="loginSubmit">
          Verify Email
        </button>
      </div>
    </div>
  );
}
