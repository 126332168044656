import { Mobile, Tablet, Desktop } from '../styles/Responsive';
import ForgetPasswordForm from '../components/forms/ForgetPasswordForm';
import ImgSlider from '../components/imgSlider/ImgSlider';
import './forgetPassword.scss';

export default function ForgetPassword(props) {

  console.log('Config : ' + JSON.stringify(process.env));
  return (
    <>
      <Mobile>
        <div className="m-loginContainer">
          <ForgetPasswordForm getToken={props.getToken} />
        </div>
      </Mobile>
      <Tablet>
        <div className="t-loginContainer">
          <div className="t-loginContent__grid">
            <ForgetPasswordForm getToken={props.getToken} />
            <ImgSlider />
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="loginContainer">
          <ImgSlider />
          <ForgetPasswordForm getToken={props.getToken} />
        </div>
      </Desktop>
    </>
  );
}
