import {
	CognitoUserPool,
	CognitoUserAttribute,
} from 'amazon-cognito-identity-js';

var poolData = {
	UserPoolId: process.env.REACT_APP_UserPoolId,
	ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

function signUp(password, name, email, phone_number) {
	var attributeList = [];
	
	var dataName = {
		Name: 'name',
		Value: name,
	};
	var dataEmail = {
		Name: 'email',
		Value: email,
	};
	var dataPhone = {
		Name: 'phone_number',
		Value: "+91" + phone_number,
	};

	var attributeName = new CognitoUserAttribute(dataName);
	var attributeEmail = new CognitoUserAttribute(dataEmail);
	var attributePhone = new CognitoUserAttribute(dataPhone);

	attributeList.push(attributeName);
	attributeList.push(attributeEmail);
	attributeList.push(attributePhone);

	return new Promise((resolve, reject) =>
		userPool.signUp(email, password, attributeList, null, (err, result) => {
			if (err) {
				reject(err);
				return;
			}
			var cognitoUser = result.user;
			console.log('user name is ' + cognitoUser.getUsername());
			resolve(result);
		}),
	);
}

export default signUp;
