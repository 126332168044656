import { useEffect, useState } from "react";
import Input from "./Input";
import "./loginForm.scss";
import userIcon from "./../../assets/icons/user.svg";
import { useNavigate } from "react-router-dom";
import loginUser from "../../logic/auth/loginUser";
import { useDispatch, useSelector } from "react-redux";
import { GetUserProfile } from "../../logic/store/actions/actionConstants";
import Network from "../../logic/api";
import store from "../../logic/store/store";
import { Loader } from "rsuite";

export default function LoginForm(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [loading, setLoading] = useState(false);

  function detectEmailInput(e) {
    setEmailInput(e.target.value);
  }

  function detectPasswordInput(e) {
    setPasswordInput(e.target.value);
  }

  useEffect(() => {
    console.log("auth: " + JSON.stringify(auth));
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      loginUser(emailInput, passwordInput)
        .then((res) => {
          return dispatch(
            Network.networkCall({
              action: GetUserProfile,
              data: {
                user_id: emailInput,
              },
            })
          )
            .then(function (result) {
              if (result.success) {
                console.log("Successfully retrieved user profile.", result);
                if (result.data.phone_confirmed === false) {
                  store.dispatch({
                    type: "CachePassword",
                    payload: {
                      password: passwordInput,
                    },
                  });
                  setLoading(false);
                  navigate(
                    "/confirm/:email/:phone/phoneConfirmedFalse/:creation_ts"
                  );
                } else if (result.data.address) {
                  setLoading(false);
                  store.dispatch({
                    type: "CachePassword",
                    payload: {
                      password: passwordInput,
                    },
                  });
                  if (result.data.role_id === 0) {
                    navigate("/auditionee");
                  } else {
                    navigate("/audition");
                  }
                } else {
                  setLoading(false);
                  navigate("/create-profile");
                }
              }
            })
            .catch(function (error) {
              setLoading(false);
              console.log("Failed to get user profile." + error);
            });
        })
        .catch((err) => {
          if (err.code === "UserNotConfirmedException") {
            setLoading(false);
            store.dispatch({
              type: "AuthFailure",
              payload: {
                user_id: emailInput,
                password: passwordInput,
              },
            });
            let name = false;
            navigate("/confirm/emailConfirmedFalse/:phone/:name/:creation_ts");
          } else {
            setLoading(false);
            console.log("Failed to Login User." + err);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (emailInput !== "" && passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg100");
    } else if (emailInput !== "" || passwordInput !== "") {
      document.getElementById("loginSubmit").classList.add("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    } else {
      document.getElementById("loginSubmit").classList.remove("submitProg50");
      document.getElementById("loginSubmit").classList.remove("submitProg100");
    }
  });

  // async function getLocation() {
  //   try {
  //     const response = await fetch(
  //       "https://tnp-utils.s3.ap-south-1.amazonaws.com/location.json"
  //     );
  //     const locations = await response.json();
  //     let locData = [];
  //     let statesArray = [];

  //     locations.forEach((location) => {
  //       Object.keys(location).forEach((state, i) => {
  //         statesArray.push(state);
  //         location[state].forEach((distObj, j) => {
  //           Object.keys(distObj).forEach((dist, k) => {
  //             distObj[dist].forEach((pinObj) => {
  //               Object.keys(pinObj).forEach((pin) => {
  //                 pinObj[pin].forEach((village) => {
  //                   locData.push({
  //                     state: state,
  //                     dist: dist,
  //                     pin: pin,
  //                     village: village,
  //                   });
  //                 });
  //               });
  //             });
  //           });
  //         });
  //       });
  //     });
  //     let stateDataObj = [...new Set(locData.map((item) => item.state))].map(
  //       (item) => ({ label: item, value: item })
  //     );
  //     setLocations(locData);
  //     setStateData(stateDataObj);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  return (
    <div className="loginFormContainer">
      {loading && (
        <Loader backdrop center size="md" content="Loading..." vertical />
      )}
      <h2>Welcome, Login to Continue</h2>
      <br />
      <form id="loginForm" onSubmit={handleSubmit}>
        <Input
          type="email"
          inputId="email"
          required="required"
          labelId="loginEmailLabel"
          labelString="E-MAIL ADDRESS"
          detectInput={detectEmailInput}
        />
        <Input
          type="password"
          inputId="password"
          required="required"
          labelId="loginPasswordLabel"
          labelString="PASSWORD"
          detectInput={detectPasswordInput}
        />
      </form>
      <div className="checkboxContainer">
        <a
          className="forget-password"
          onClick={() => {
            navigate("/forget-password");
          }}
        >
          Forgot Password?
        </a>
      </div>
      <div className="checkboxContainer">
        <button type="submit" form="loginForm" id="loginSubmit">
          Login
        </button>
      </div>
      <div className="checkboxContainer">
        <div className="register-section">
          <div className="reg-icon">
            <img src={userIcon} alt="User Icon" />
          </div>
          <div className="reg-title">
            <div className="title">TNPnews employees registration.</div>
            <button
              className="register"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
