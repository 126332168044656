import { Modal, Button, Placeholder } from "rsuite";
import "./auditionProfile.scss";
import { useState, useMemo } from "react";
import profileIcon from "./../../assets/icons/profile.png";

export default function AuditionProfile(props) {
  const handleClose = () => props.modalClose();
  const [age, setAge] = useState();
  

  function numberFormat() {
    if (props.user.phone_number) {
      return parseInt(props.user.phone_number.toString().replace('91', ''));
    }
  }

  function AgeCalculator() {
    if (props.user.dob) {
      const birthYear = new Date(props.user.dob).getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear;
    }
  }

  const auditionTimeFormat = (timestemp) => {
    const formatted = new Intl.DateTimeFormat("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(timestemp);

    return formatted;
  };

  console.log("props.user.payment_timestamp", props.user.payment_timestamp);
  return (
    <Modal size="xs" open={props.open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <strong>Auditionee Details</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="model-body">
          <div className="profile">
            <div className="img">
              <img
                src={props.user.img}
                alt="Profile Pic"
                className="profile-pic rounded-circle"
              />
            </div>
            <div className="name">
              <div className="text-secondary">Name</div>
              <div>
                <strong>
                  {props.user.name} | {AgeCalculator()} | {props.user.gender}
                </strong>
              </div>
            </div>
          </div>

          <div className="profile">
            <div className="name col-5">
              <div className="text-secondary">Mobile No</div>
              <div>{numberFormat()}</div>
            </div>
            <div className="name col-7">
              <div className="text-secondary">Email ID</div>
              <div>{props.user.user_id}</div>
            </div>
          </div>
          <div className="profile">
            <div className="name">
              <div className="text-secondary">Location</div>
              <div>
                {props.user.address +
                  ", " +
                  props.user.block +
                  ", " +
                  props.user.district +
                  ", " +
                  props.user.state}
              </div>
            </div>
          </div>
          {props.user.payment_timestamp && (
            <div className="profile">
              <div className="name col-5">
                <div className="text-secondary">Paid</div>
                <div>Rs. 999/-</div>
              </div>
              <div className="name col-7">
                <div className="text-secondary">Payment Date</div>
                <div>{auditionTimeFormat(props.user.payment_timestamp)}</div>
              </div>
            </div>
          )}

          {props.user.aud_feedback != "-" || props.user.aud_feedback === undefined && (
            <div className="profile">
              <div className="name">
                <div className="text-secondary">Audtion feedback</div>
                <div>{props.user.aud_feedback}</div>
              </div>
            </div>
          )}

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
