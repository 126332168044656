import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_id: null,
  creation_ts: null,
  otp: null,
  phone_confirmed: false,
  email_confirmed: false,
  phone_number: null,
  password: null,
  name: null,
  role_id: null,
  imageURL: null,
  block: null,
  district: null,
  state: null,
  gender: null,
  pincode: null,
  address: null,
  education: null,
  dob: null,
  isLoading: false,
  isError: false,
  payment_status: null,
  payment_id: null,
  privileges: null,
  aud_schedule: null,
  aud_status: null,
  aud_feedback: null,
  aud_address: null,
  is_active: null,
  payment_timestamp:null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    GetUserProfileSuccess: (state, action) => {
      state.user_id = action.payload.user_id;
      state.creation_ts = action.payload.creation_ts;
      state.otp = action.payload.otp;
      state.phone_confirmed = action.payload.phone_confirmed;
      state.email_confirmed = action.payload.email_confirmed;
      state.phone_number = action.payload.phone_number;
      state.name = action.payload.name;
      state.role_id = action.payload.role_id;
      state.imageURL = action.payload.img;
      state.block = action.payload.block;
      state.district = action.payload.district;
      state.state = action.payload.state;
      state.gender = action.payload.gender;
      state.pincode = action.payload.pincode;
      state.address = action.payload.address;
      state.education = action.payload.education;
      state.dob = action.payload.dob;
      state.payment_status = action.payload.payment_status;
      state.payment_id = action.payload.payment_id;
      state.privileges = action.payload.privileges;
      state.aud_schedule = action.payload.aud_schedule;
      state.aud_status = action.payload.aud_status;
      state.aud_feedback = action.payload.aud_feedback;
      state.aud_address = action.payload.aud_address;
      state.is_active = action.payload.is_active;
      state.payment_timestamp = action.payload.payment_timestamp;
      
    },
    GetUserProfileFailure: (state, action) => {
      state.user_id = null;
      state.creation_ts = null;
      state.otp = null;
      state.phone_confirmed = false;
      state.email_confirmed = false;
      state.phone_number = null;
      state.password = null;
      state.name = null;
      state.role_id = null;
      state.imageURL = null;
      state.block = null;
      state.district = null;
      state.state = null;
      state.gender = null;
      state.pincode = null;
      state.address = null;
      state.education = null;
      state.dob = null;
      state.payment_status = null;
      state.payment_id = null;
      state.privileges = null;
      state.aud_schedule = null;
      state.aud_status = null;
      state.aud_feedback = null;
      state.aud_address = null;
      state.is_active = null;
    },
    CreateUserSuccess: (state, action) => {
      state.user_id = action.payload.user_id;
      state.creation_ts = action.payload.creation_ts;
      state.otp = action.payload.otp;
      state.phone_confirmed = action.payload.phone_confirmed;
      state.email_confirmed = action.payload.email_confirmed;
      state.phone_number = action.payload.phone_number;
      state.name = action.payload.name;
    },
    CreateUserFailure: (state, action) => {
      state.user_id = null;
      state.creation_ts = null;
      state.otp = null;
      state.phone_confirmed = false;
      state.email_confirmed = false;
      state.phone_number = null;
      state.name = null;
    },
    EmailConfirmedSuccess: (state, action) => {
      state.email_confirmed = action.payload.email_confirmed;
    },
    EmailConfirmedFailure: (state, action) => {
      state.email_confirmed = false;
    },
    ValidatePhoneSuccess: (state, action) => {
      state.phone_confirmed = action.payload.phone_confirmed;
    },
    ValidatePhoneFailure: (state, action) => {
      state.phone_confirmed = false;
    },
    CachePassword: (state, action) => {
      state.password = action.payload.password;
    },
    CleanPassword: (state, action) => {
      state.password = null;
    },
    UploadUserPictureSuccess: (state, action) => {
      state.imageURL = action.payload.imageURL;
    },
    UploadUserPictureFailure: (state, action) => {
      state.imageURL = null;
    },
    UpdateUserProfileSuccess: (state, action) => {
      state.block = action.payload.block;
      state.district = action.payload.district;
      state.state = action.payload.state;
      state.gender = action.payload.gender;
      state.pincode = action.payload.pincode;
      state.address = action.payload.address;
      state.education = action.payload.education;
      state.dob = action.payload.dob;
      state.user_id = action.payload.user_id;
    },
    UpdateUserProfileFailure: (state, action) => {
      state.block = null;
      state.district = null;
      state.state = null;
      state.gender = null;
      state.pincode = null;
      state.address = null;
      state.education = null;
      state.dob = null;
    },
    AuthFailure: (state, action) => {
      state.user_id = action.payload.user_id;
      state.password = action.payload.password;
    },
    UpdatePaymentStatusSuccess: (state, action) => {
      state.user_id = action.payload.user_id;
      state.payment_status = action.payload.payment_status;
      state.payment_id = action.payload.payment_id;
      state.payment_timestamp = action.payload.payment_timestamp;
    },
    UpdatePaymentStatusFailure: (state, action) => {
      state.user_id = null;
      state.payment_status = null;
      state.payment_id = null;
      state.payment_timestamp = null;
    },
    UserCleanUp:(state, action) => {
     return initialState;
    }
  },
});

export const user = (state) => state.user;
export default userSlice.reducer;
