import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import store from "./../store/store";

var poolData = {
  UserPoolId: process.env.REACT_APP_UserPoolId,
  ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

async function confirmSignUp(verificationCode, username) {
  var userData = {
    Username: username,
    Pool: userPool,
  };

  var cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) =>
    cognitoUser.confirmRegistration(
      verificationCode,
      true,
      function (err, result) {
        if (err) {
          store.dispatch({
            type: "EmailConfirmedFailure",
            payload: {
              email_confirmed: false,
            },
          });
          reject(err);
          return;
        }
		store.dispatch({
            type: "EmailConfirmedSuccess",
            payload: {
              email_confirmed: true,
            },
        });
        resolve(result);
      }
    )
  );
}

export default confirmSignUp;
