import auditionIcon from "./../../assets/icons/Audition.svg";
import newsIcon from "./../../assets/icons/news.svg";
import accountIcon from "./../../assets/icons/Accounts.svg";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Dropdown } from "rsuite";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <NavLink
          exact
          activeClassName="active"
          to={
            user.role_id === 1 || user.role_id === 8
              ? "/audition"
              : "/auditionee"
          }
        >
          <a className="navbar-brand" href="/"></a>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          {user.role_id === 1 || user.role_id === 8 || user.role_id === 0 ? (
            <></>
          ) : (
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink exact activeClassName="active" to="/audition">
                  <img src={auditionIcon} /> Audition
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact activeClassName="active" to="/admin">
                  <img src={auditionIcon} /> Admin
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact activeClassName="active" to="/day-plan">
                  <img src={accountIcon} /> Day Plan
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact activeClassName="active" to="/news">
                  <img src={newsIcon} /> News
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact activeClassName="active" to="/accounts">
                  <img src={accountIcon} /> Accounts
                </NavLink>
              </li>
            </ul>
          )}
        </div>
        <div className="profile">
          <div className="row">
            <div className="col">
              <img
                src={user.imageURL}
                alt="Profile Pic"
                className="profile-pic rounded-circle"
              />
            </div>
            <div className="col">
              <div className="pname text-white">Welcome</div>
              <div className="pname dropdown">
                <a
                  className="dropdown-toggle text-white"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {user.name}{" "}
                  {user.role_id === 1
                    ? "(Vendor)"
                    : user.role_id === 8
                    ? "(Audition Judge)"
                    : user.role_id === 0
                    ? "(Auditionee)"
                    : "(Employee)"}
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <NavLink
                      className="dropdown-item"
                      exact
                      activeClassName="active"
                      to="/profile"
                    >
                      Profile
                    </NavLink>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
