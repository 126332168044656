import { Modal, Button, Placeholder } from "rsuite";
import "./ExpenseComment.scss";
import { useState, useMemo, useEffect } from "react";
import { Roles, Levels } from "./../../routes/Paths";

export default function UpdateAuditionStatus(props) {
  const [feedback, setFeedback] = useState();

  const handleClose = () => props.modalClose();
  const handleConfirm = () => {
    let audStatus;
    if (props.actionType=== 'Accept') {
        audStatus=1;
    } else if(props.actionType=== 'On Hold'){
        audStatus=2;
    }
    else{
        audStatus=3;
    }
    props.submitReason(feedback, audStatus);
  };

  return (
    <Modal size="sm" open={props.open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <strong className="text-danger">
            Auditionee {props.actionType} Feedback
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="model-body">
          <div className="profile">
            <div className="name col-4">
              <div className="text-secondary">Name</div>
              <div>{props.user.name}</div>
            </div>
            <div className="name col-4">
              <div className="text-secondary">Mobile No</div>
              <div>{props.user.phone_number}</div>
            </div>
            <div className="name col-4">
              <div className="text-secondary">Email ID</div>
              <div>{props.user.user_id}</div>
            </div>
          </div>

          <div className="profile">
            <div className="col-12">
              <textarea
                className="reason-text"
                placeholder="Enter your Feedback"
                onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={handleConfirm}
          className="btn btn-default"
        >
          Submit
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
