const get = 'GET';
const post = 'POST';
const put = 'PUT';
const deleteMethod = 'DELETE';

export const CreateUser = {
	api: process.env.REACT_APP_createUserAPI,
	method: post,
	noAuth: true
};

export const GetUsers = {
	api: process.env.REACT_APP_getUsersAPI,
	method: post,
};

export const UpdateUserRoles = {
	api: process.env.REACT_APP_updateUserRolesAPI,
	method: post,
};

export const UpdateUserProfile = {
	api: process.env.REACT_APP_updateUserProfileAPI,
	method: post,
};

export const ResendOtp = {
	api: process.env.REACT_APP_resendOtpAPI,
	method: post,
	noAuth: true
};

export const ValidatePhone = {
	api: process.env.REACT_APP_validatePhoneAPI,
	method: post,
	noAuth: true
};

export const GetUserProfile = {
	api: process.env.REACT_APP_getUserProfileAPI,
	method: post,
};

export const ApproveNews = {
	api: process.env.REACT_APP_approveNewsAPI,
	method: post,
};

export const ApproveExpense = {
	api: process.env.REACT_APP_approveNewsAPI,
	method: post,
};

export const UploadUserPicture = {
	api: process.env.REACT_APP_uploadUserPictureAPI,
	method: post,
};

export const ValidateEmail = {
	api: process.env.REACT_APP_validateEmailAPI,
	method: post,
	noAuth: true
};
export const UpdatePaymentStatus = {
	api: process.env.REACT_APP_updatePaymentStatusAPI,
	method: post,
};
export const UpdateAuditionData = {
	api: process.env.REACT_APP_updateAuditionDataAPI,
	method: post,
};