import {CognitoUserPool, CognitoUser} from 'amazon-cognito-identity-js';

var poolData = {
	UserPoolId: process.env.REACT_APP_UserPoolId,
	ClientId: process.env.REACT_APP_ClientId,
};

var userPool = new CognitoUserPool(poolData);

function resetToken(username, verificationCode, newPassword) {
	var userData = {
		Username: username,
		Pool: userPool,
	};

	var cognitoUser = new CognitoUser(userData);

	return new Promise((resolve, reject) =>
		cognitoUser.confirmPassword(verificationCode, newPassword, {
			onSuccess: function (result) {
				// successfully initiated reset password request
				console.log('Successfully reset the password: ' + result);
				resolve(result);
			},
			onFailure: function (err) {
				alert(err.message || JSON.stringify(err));
				reject(err);
			},
		}),
	);
}

export default resetToken;
