import { useEffect, useState } from "react";
import Input from "./Input";
import "./registerForm.scss";
import backIcon from "./../../assets/icons/back-arraw.svg";
import { useNavigate } from "react-router-dom";
import signUp from "../../logic/auth/signUp";
import Network from "../../logic/api";
import { CreateUser } from "../../logic/store/actions/actionConstants";
import { useDispatch, useSelector } from "react-redux";
import store from "../../logic/store/store";
import { Loader } from "rsuite";

export default function RegisterForm(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [phoneInput, setPhoneInput] = useState("");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [fullNameInput, setFullNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [loading, setLoading] = useState(false);

  function detectPhoneInput(e) {
    setPhoneInput(e.target.value);
  }

  function detectEmailInput(e) {
    setEmailInput(e.target.value);
  }

  function detectFullNameInput(e) {
    setFullNameInput(e.target.value);
  }

  function detectPasswordInput(e) {
    setPasswordInput(e.target.value);
  }

  function detectConfirmPasswordInput(e) {
    setConfirmPasswordInput(e.target.value);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      signUp(passwordInput, fullNameInput, emailInput, phoneInput)
        .then((res) => {
          return dispatch(
            Network.networkCall({
              action: CreateUser,
              data: {
                phone_number: "91" + phoneInput,
                email: emailInput,
                name: fullNameInput,
                os: "web",
                manufacturer: "web",
                user_id: emailInput,
                phone_confirmed: false,
                email_confirmed: false,
              },
            })
          )
            .then(function (result) {
              store.dispatch({
                type: "CachePassword",
                payload: {
                  password: passwordInput,
                },
              });
              console.log("Successfully created User.", result);
              if (result.success) {
                setLoading(false);
                navigate(
                  "/confirm/" +
                    emailInput +
                    "/" +
                    phoneInput +
                    "/" +
                    fullNameInput +
                    "/" +
                    result.data.creation_ts
                );
              } else {
                setLoading(false);
              }
            })
            .catch(function (error) {
              setLoading(false);
              console.log("Failed update voting status.", error);
            });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (phoneInput !== "" && emailInput !== "") {
      document.getElementById("registerSubmit").classList.add("submitProg50");
    }
    if (
      phoneInput !== "" &&
      emailInput !== "" &&
      fullNameInput !== "" &&
      passwordInput !== "" &&
      confirmPasswordInput !== ""
    ) {
      document.getElementById("registerSubmit").classList.add("submitProg100");
    }
  });

  return (
    <div className="loginFormContainer">
      {loading &&  <Loader backdrop center size="md" content="Loading..." vertical />}
          <div className="reg-head">
            <div className="back">
              <img
                onClick={() => {
                  navigate("/auth/login");
                }}
                src={backIcon}
                alt="Back"
              />
            </div>
            <div className="heade-title">
              <h2>TNPnews employees registration.</h2>
              <p>
                Please provide the following details to proceed with your
                registration
              </p>
            </div>
          </div>

          <br />
          <form id="registerForm" onSubmit={handleSubmit}>
            <Input
              type="text"
              inputId="mobile"
              required="required"
              labelId="registerMobileLabel"
              labelString="Mobile Number"
              detectInput={detectPhoneInput}
            />
            <Input
              type="text"
              inputId="email"
              required="required"
              labelId="registeEmailLabel"
              labelString="Email"
              detectInput={detectEmailInput}
            />
            <Input
              type="text"
              inputId="fullName"
              required="required"
              labelId="registefullNameLabel"
              labelString="Full Name"
              detectInput={detectFullNameInput}
            />
            <Input
              type="password"
              inputId="password"
              required="required"
              labelId="registePasswordLabel"
              labelString="Password"
              detectInput={detectPasswordInput}
            />
            <Input
              type="password"
              inputId="cPassword"
              required="required"
              labelId="registeConfirmPasswordLabel"
              labelString="Confirm Password"
              detectInput={detectConfirmPasswordInput}
            />
          </form>

          <div className="checkboxContainer">
            <button type="submit" form="registerForm" id="registerSubmit">
              Register
            </button>
          </div>
    </div>
  );
}
