import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import auth from './reducers/auth';
import user from './reducers/user';
import news from './reducers/news';
import users from './reducers/users';
import expense from './reducers/expense';

const appReducer = combineReducers({
	auth,
	user,
	news,
	users,
	expense
});

const rootReducer = (state, action) => {
	if (action.type === 'currentUser/logout') {
		state = undefined;
	}
	return appReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
});

export default store;
