import { useEffect, useState } from "react";
import { Timeline, Grid, Row, Col } from "rsuite";
import Navbar from "../components/navbar/Navbar";
import "./Auditionee.scss";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from '@rsuite/icons/legacy/Check';


export default function Auditionee() {
  const user = useSelector((state) => state.user);
  const accountCreatedData = new Date(user.creation_ts);
  const paymentDate = new Date(user.payment_timestamp);

  return (
    <div>
      <Navbar></Navbar>
      <div className="TimelineContainer">
        <h4 style={{ marginBottom: "20px" }}>Welcome {user.name}!</h4>
        <Timeline className="custom-timeline" align={"left"}>
          <Timeline.Item dot={<CheckIcon style={{ background: '#15b215', color: '#fff', height:10, width:10, borderRadius:5 }} />}>
            <p>{accountCreatedData.toDateString()}</p>
            <p>Phone number & email id are verified successfully</p>
          </Timeline.Item>

          {user.payment_status != 1 ? (
            <Timeline.Item >
              <p>Waiting for payment</p>
            </Timeline.Item>
          ) : (
            <>
              <Timeline.Item dot={<CheckIcon style={{ background: '#15b215', color: '#fff', height:10, width:10, borderRadius:5 }} />}>
                <p>{paymentDate.toDateString()}</p>
                <p>Your Payment has been received successfully.</p>
              </Timeline.Item>

              <Timeline.Item >
                <p>You will be communicated with the audition date soon</p>
              </Timeline.Item>


              {/* <Timeline.Item dot={<CheckIcon style={{ background: '#15b215', color: '#fff', height:10, width:10, borderRadius:5 }} />}>
                <p>2018-03-12</p>
                <p>Order out of the library</p>
              </Timeline.Item>
              <Timeline.Item dot={<CheckIcon style={{ background: '#15b215', color: '#fff', height:10, width:10, borderRadius:5 }} />}>
                <p>2018-03-15</p>
                <p>Sending you a piece</p>
              </Timeline.Item> */}
            </>
          )}
        </Timeline>
      </div>
    </div>
  );
}
