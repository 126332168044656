import { useEffect, useState } from "react";
import Input from "./Input";
import "./confirmEmailAndPhoneForm.scss";
import backIcon from "./../../assets/icons/back-arraw.svg";
import { useNavigate } from "react-router-dom";
import confirmSignUp from "../../logic/auth/confirmSignUp";
import authError from "../../logic/auth/errors";
import Network from "../../logic/api";
import {
  ValidatePhone,
  ResendOtp,
  ValidateEmail,
} from "../../logic/store/actions/actionConstants";
import { useDispatch, useSelector } from "react-redux";
import loginUser from "../../logic/auth/loginUser";
import resendEmailConfirmation from "../../logic/auth/resendEmailConfirmation";
import { Loader } from "rsuite";
export default function ConfirmEmailAndPhoneForm(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [phoneOtpInput, setPhoneOtpInput] = useState("");
  const [emailOtpInput, setEmailOtpInput] = useState("");
  const [enableResendEmailOtp, setEnableResendEmailOtp] = useState(
    props.email === "emailConfirmedFalse" ? "submitProg100" : "submitProg25"
  );
  const [enableResendPhoneOtp, setEnableResendPhoneOtp] = useState(
    props.name === "phoneConfirmedFalse" ? "submitProg100" : "submitProg25"
  );

  const [loading, setLoading] = useState(false);

  function detectPhoneOtpInput(e) {
    setPhoneOtpInput(e.target.value);
  }

  function detectEmailOtpInput(e) {
    setEmailOtpInput(e.target.value);
  }

 

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (user.phone_confirmed === false && user.email_confirmed === false) {
      confirmSignUp(emailOtpInput, user.user_id)
        .then((res) => {
          return dispatch(
            Network.networkCall({
              action: ValidateEmail,
              data: {
                user_id: user.user_id,
              },
            })
          )
            .then((result) => {
              verifyPhoneOtp(phoneOtpInput);
              setLoading(false);
              if (result.success) {
                console.log("ValidateEmail result ", result);
              }
            })
            .catch((err) => {
              console.log("Failed to call ValidateEmail.", err);
            });
        })
        .catch((err) => {
          setLoading(false);
          console.log("Failed to validate email.", err);
          authError(err.code);
          setEnableResendEmailOtp("submitProg100");
        });
    } else {
      verifyPhoneOtp(phoneOtpInput);
    }
  };

  function verifyPhoneOtp(otp) {
    setLoading(true);
    return dispatch(
      Network.networkCall({
        action: ValidatePhone,
        data: {
          user_id: user.user_id,
          otp: otp,
        },
      })
    )
      .then(function (result) {
        if (result.success) {
          setLoading(false);
          console.log("Successfully confirmed phone number.", result);
          loginUser(user.user_id, user.password)
            .then((res) => {
              if (res.success) {
                console.log("login user Successfully..", res);
                navigate("/create-profile");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log("Failed to login user..", result);
              authError(err.code);
            });
        } else {
          setLoading(false);
          console.log(result);
          setEnableResendPhoneOtp("submitProg100");
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Failed to validate phone." + error);
      });
  }

  useEffect(() => {
    if (phoneOtpInput !== "" && emailOtpInput !== "") {
      document.getElementById("registerSubmit").classList.add("submitProg100");
    } else if (emailOtpInput !== "" || phoneOtpInput !== "") {
      document.getElementById("registerSubmit").classList.add("submitProg50");
      document.getElementById("registerSubmit").classList.remove("submitProg100");
    } else {
      document.getElementById("registerSubmit").classList.remove("submitProg50");
      document.getElementById("registerSubmit").classList.remove("submitProg100");
    }
  });

  function resendEmailOtp() {
    setLoading(true);
    try {
      resendEmailConfirmation(user.user_id).then(function (result) {
        setLoading(false);
        setEnableResendEmailOtp("submitProg25");
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  function resendPhoneOtp() {
    setLoading(true);
    return dispatch(
      Network.networkCall({
        action: ResendOtp,
        data: {
          user_id: user.user_id,
        },
      })
    )
      .then(function (result) {
        if (result.success) {
          setLoading(false);
          console.log("Successfully send phone otp.", result);
          setEnableResendPhoneOtp("submitProg25");
        } else {
          setLoading(false);
          console.log(result);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("Failed to send phone otp." + error);
      });
  }

  return (
    <div className="loginFormContainer">
      {loading &&  <Loader backdrop center size="md" content="Loading..." vertical />}
          <div className="reg-head">
            <div className="back">
              <img
                onClick={() => {
                  navigate("/auth/login");
                }}
                src={backIcon}
                alt="Back"
              />
            </div>
            <div className="heade-title">
              <h2>TNPnews employees registration.</h2>
              <p>Please provide the OTPs received on your email and phone.</p>
            </div>
          </div>

          <br />
          <form id="registerForm" onSubmit={handleSubmit}>
            <Input
              type="number"
              inputId="mobileOtp"
              required="required"
              labelId="confirmMobileLabel"
              labelString="Mobile OTP"
              detectInput={detectPhoneOtpInput}
            />
            <Input
              type="number"
              inputId="emailOtp"
              required="required"
              labelId="confirmEmailLabel"
              labelString="Email OTP"
              detectInput={detectEmailOtpInput}
            />
          </form>

          <div className="checkboxContainer">
            <button type="submit" form="registerForm" id="registerSubmit">
              Register
            </button>
          </div>

          <div className="checkboxContainer">
            <button
              type="submit"
              onClick={resendEmailOtp}
              id="confirm"
              className={enableResendEmailOtp}
            >
              Resend Email Otp
            </button>
            <span
              type="submit"
              id="confirmGap"
              className={enableResendPhoneOtp}
            ></span>
            <button
              type="submit"
              onClick={resendPhoneOtp}
              id="confirm"
              className={enableResendPhoneOtp}
            >
              Resend Mobile Otp
            </button>
          </div>
    </div>
  );
}
