import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { Paths } from "./routes/Paths";

import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import ForgetPassword2 from "./pages/ForgetPassword2";
import Dashboard from "./pages/Dashboard";
import Audition from "./pages/Audition";
import Account from "./pages/Account";
import Admin from "./pages/Admin";
import News from "./pages/News";
import Profile from "./pages/Profile";
import RegisterPersonal from "./pages/RegisterPersonal";
import 'rsuite/dist/rsuite.min.css';
import NewsDetail from "./pages/NewsDetail";
import ExpenseDetail from "./pages/ExpenseDetail";
import DayPlan from "./pages/DayPlan";
import ConfirmEmailAndPhone from "./pages/ConfirmEmailAndPhone";
import CreateProfile from "./pages/CreateProfile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Auditionee from "./pages/Auditionee";

export default function App() {
  // const [token, setToken] = useState(false);

  // function guestClick() {
  //   setToken(true);
  // }

  // if (!token) {
  //   return <Login setToken={setToken} getToken={guestClick} />;
  // }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path={Paths.Dashboard.path} element={<Dashboard/>} />
        <Route path={Paths.Audition.path} element={<Audition />} />
        <Route path={Paths.Account.path} element={<Account />} />
        <Route path={Paths.Admin.path} element={<Admin />} />
        <Route path={Paths.News.path} element={<News />} />
        <Route path={Paths.DayPlan.path} element={<DayPlan />} />
        <Route path={Paths.NewsDetail.path} element={<NewsDetail />} />
        <Route path={Paths.ExpenseDetail.path} element={<ExpenseDetail />} />
        <Route path={Paths.Profile.path} element={<Profile />} />
        <Route path={Paths.Login.path} element={<Login />} />
        <Route path={Paths.Register.path} element={<Register />} />
        <Route path={Paths.ForgetPassword.path} element={<ForgetPassword />} />
        <Route path={Paths.ForgetPassword2.path} element={<ForgetPassword2 />} />
        <Route path={Paths.RegisterFormPersonal.path} element={<RegisterPersonal />} />
		    <Route path={Paths.ConfirmEmailAndPhone.path} element={<ConfirmEmailAndPhone />} />
		    <Route path={Paths.CreateProfile.path} element={<CreateProfile />} />
		    <Route path={Paths.PrivacyPolicy.path} element={<PrivacyPolicy />} />
		    <Route path={Paths.Auditionee.path} element={<Auditionee />} />
      </Routes>
    </Router>
  );
}
