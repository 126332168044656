import React, { useEffect, useMemo, useState } from "react";
import { Roles, Levels } from "./../routes/Paths";
import { DateRangePicker, CheckPicker, Loader, Placeholder, Input, Table, Button, ButtonToolbar } from 'rsuite';
import './Admin.scss';

//icons 
import Navbar from './../../src/components/navbar/Navbar';
import previousIcon from './../assets/icons/previous.svg';
import nextIcon from './../assets/icons/next.svg';
import downloadIcon from './../assets/icons/download.svg';
import calenderIcon from './../assets/icons/calender.svg';
import emailIcon from './../assets/icons/email.svg';
import rupeeIcon from './../assets/icons/rupee.svg';

import UpdateRole from "../components/modal/UpdateRole";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;



export default function Admin() {
    const roleData = Roles.map(item => ({ label: item.role, value: item.id }));
    var auditionUsers = {
        "total_auditionees": 120,
        "selected_auditionees": 50,
        "rejected_auditionees": 40,
        "pending_autionees": 30,
        "page_size": 15,
        "page_no": 1,
        "auditionees": [
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "Agra",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [1,3],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 1,
                "levelid": 4,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Prateek Kumar"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Delhi",
                "roleid": [2,3],
                "privileges": "3FFF",
                "aud_schedule": "",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 2,
                "aud_status": 2,
                "levelid": 2,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Krishna"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Bihar",
                "roleid": [3],
                "privileges": "3FFF",
                "aud_schedule": "",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 0,
                "aud_status": 3,
                "levelid": 3,
                "address": "19, Mj Street. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Aditya"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [4],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 4,
                "levelid": 2,
                "address": "18, Str. mxp, Delhi",
                "aud_feedback": "Amazing Candidate",
                "is_active": 0,
                "name": "Reetesh Dwivedi"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [5,4],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Very Good",
                "is_active": 0,
                "name": "Murari Kumar"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [1,5],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Prateek Kumar"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [2,5],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Krishna"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [3,8],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "19, Mj Street. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Aditya"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [4,7],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "18, Str. mxp, Delhi",
                "aud_feedback": "Amazing Candidate",
                "is_active": 0,
                "name": "Reetesh Dwivedi"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [5,6,7],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Very Good",
                "is_active": 0,
                "name": "Murari Kumar"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [1,8],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Prateek Kumar"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [2,5],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Krishna"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [3,4,5],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "19, Mj Street. 1x, xyz",
                "aud_feedback": "Good",
                "is_active": 0,
                "name": "Aditya"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [4,2],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "18, Str. mxp, Delhi",
                "aud_feedback": "Amazing Candidate",
                "is_active": 0,
                "name": "Reetesh Dwivedi"
            },
            {
                "user_id": "ab@gmail.com",
                "creation_ts": 1694763307952,
                "block": "xyz",
                "district": "Agra",
                "state": "Uttar Pradesh",
                "roleid": [1,5],
                "privileges": "3FFF",
                "aud_schedule": "16 Dec, 2023",
                "phone_no": 8721554478,
                "gender": "M",
                "payment_status": 1,
                "aud_status": 2,
                "levelid": 1,
                "address": "12, Str. 1x, xyz",
                "aud_feedback": "Very Good",
                "is_active": 0,
                "name": "Murari Kumar"
            }
        ]
    };
    const [locations, setLocations] = useState([]);
    const [loader, setLoader] = useState(true);
    const [userList, setUserList] = useState([]);
    const [searchText, setSearch] = useState("");
    const [activeRole, setRole] = useState([]);
    const [activeState, setState] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [activeDist, setDist] = useState([]);
    const [distData, setDistData] = useState([]);
    const [activeVillage, setVillage] = useState([]);
    const [villageData, setVillageData] = useState([]);

    const [activeDateRange, setDateRange] = useState([]);
    const [activeUser, setActiveUser] = useState({});
    const [open, setOpen] = useState(false);


    // Add default value on page load
    useEffect(() => {
        setUserList(auditionUsers.auditionees);
        getLocation();
    }, []);




    async function getLocation() {
        try {
            const response = await fetch('https://tnp-utils.s3.ap-south-1.amazonaws.com/location.json');
            const locations = await response.json();
            let locData = [];
            let statesArray = [];
            let temp = [];

            locations.forEach((location) => {
                Object.keys(location).forEach((state, i) => {
                    statesArray.push(state);
                    location[state].forEach((distObj, j) => {
                        Object.keys(distObj).forEach((dist, k) => {
                            distObj[dist].forEach(pinObj => {
                                Object.keys(pinObj).forEach(pin => {
                                    pinObj[pin].forEach(village => { locData.push({ "state": state, "dist": dist, "pin": pin, "village": village }); });
                                })
                            })
                        })
                    });
                });
            });
            let stateDataObj = [...new Set(locData.map(item => item.state))].map(item => ({ label: item, value: item }));
            setLocations(locData);
            setStateData(stateDataObj);
        } catch (err) {
            console.log(err);
        }
    }


    setTimeout(() => { setLoader(false) }, 1000);
    // Function to get filtered list
    function getFilteredList() {
        var data = userList;
        //search filter
        if (searchText != "" && searchText.length != 0)
            data = data.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));

        //state filter
        if (activeState.length != 0)
            data = data.filter(user => activeState.includes(user.state));

        //district filter 
        if (activeDist.length != 0)
            data = data.filter(user => activeDist.map(e => e.toLowerCase()).includes(user.district.toLowerCase()));

        //village filter 
        if (activeVillage.length != 0)
            data = data.filter(user => activeVillage.map(e => e.toLowerCase()).includes(user.block.toLowerCase()));

        //status filter
        if (activeRole.length != 0) {
            data = data.filter(user => {
             //   activeRole.includes(user.roleid)
                return true;
        })
        }
          

        //audition date filter
        if (activeDateRange && activeDateRange.length != 0) {
            var fromDate = new Date(activeDateRange[0]);
            var toDate = new Date(activeDateRange[1]);
            data = data.filter(user => user.aud_schedule != "");
            data = data.filter(user => {
                let scheduleDate = new Date(user.aud_schedule);
                if (fromDate <= scheduleDate && scheduleDate <= toDate)
                    return true;
            });
        }

        return data;
    }

    // Avoid duplicate function calls with useMemo
    var filteredList = useMemo(getFilteredList, [searchText, userList, activeRole, activeState, activeDateRange, activeDist, activeVillage,
        stateData, locations, distData, villageData]);


    const handleSearchChange = searchText => setSearch(searchText);
    const handleRoleChange = role => { setRole(role); };

    const handleStateChange = state => {
        let data = locations.filter(l => state.includes(l.state));
        let distData = [...new Set(data.map(item => item.dist))].map(item => ({ label: item, value: item }));
        setState(state);
        setDistData(distData);
    }
    const handleClearState = () => { setState(""); setDist(""); setVillage(""); }
    const handleClearDistrict = () => { setDist(""); setVillage(""); }
    const handleClearVillage = () => { setVillage(""); }

    const handleDistChange = dist => {
        let data = locations.filter(l => activeState.includes(l.state))
            .filter(d => dist.includes(d.dist))
        let villageData = [...new Set(data.map(item => item.village))].map(item => ({ label: item, value: item }));
        setDist(dist);
        setVillageData(villageData);
    }

    const handleVillageChange = village => {
        setVillage(village);
    }


    const handleDateRangeChange = dateRange => setDateRange(dateRange);
    const handleOpen = user => { setActiveUser(user); setOpen(true); };
    const handleClose = () => setOpen(false);
    const updateUserRole = (user) => { let index = filteredList.findIndex(e => e.user_id == user.user_id); filteredList[index] = user; setOpen(false) }
    const getRoleName = roleId => Roles.filter(r => r.id == roleId)[0].role;
    const getLevelName = levelId => Levels.filter(l => l.id == levelId)[0].level;

    return (
        <div className='container-flude'>
            <Navbar></Navbar>
            {loader && <Loader center content="Loading..." vertical />}
            {!loader &&
                <div className='container mt-5 text-danger'>
                    <h4>Employees Access Application</h4>
                    <div className='row mb-3 mt-4' >
                        <div className='col-3'>
                         
                            <Input placeholder="Search" onChange={handleSearchChange} />
                        </div>
                        <div className='col-3'>
                            <DateRangePicker
                                onChange={handleDateRangeChange} />
                        </div>

                        <div className='col-3'>
                            <CheckPicker
                                data={roleData}
                                placeholder="Select Status"
                                onChange={handleRoleChange}
                                style={{ width: '100%' }} />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-3'>
                            <CheckPicker
                                data={stateData}
                                placeholder="Search by State"
                                onChange={handleStateChange}
                                onClean={handleClearState}
                                style={{ width: '100%' }} />
                        </div>

                        <div className='col-3'>
                            <CheckPicker
                                data={distData}
                                onChange={handleDistChange}
                                placeholder="Search By District"
                                onClean={handleClearDistrict}
                                style={{ width: '100%' }} />
                        </div>

                        <div className='col-3'>
                            <CheckPicker
                                data={villageData}
                                onChange={handleVillageChange}
                                placeholder="Search by Block"
                                onClean={handleClearVillage}
                                style={{ width: '100%' }} />
                        </div>



                        <div className='col-3 text-end'>
                            <button className='btn btn-outline-info'><img src={downloadIcon} /> Download</button>
                        </div>
                    </div>
                    {/* Display audition data */}

                    <div className='row mt-3'>
                        <div className="col">
                            <Table
                                height={300}
                                rowHeight={60}
                                headerHeight={40}
                                data={filteredList}
                                onRowClick={rowData => {
                                    console.log(rowData);
                                }}
                            >
                                <Column width={70} align="center" >
                                    <HeaderCell>#</HeaderCell>
                                    <Cell >
                                        {(rowData, index) => (
                                            <span>{index + 1}</span>
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={200} >
                                    <HeaderCell>Name</HeaderCell>
                                    <Cell >
                                        {user => (
                                            <span  className='location'>  {user.name}</span>
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={200}>
                                    <HeaderCell>Phone No</HeaderCell>
                                    <Cell >
                                        {user => (
                                            <span className="location"> {user.phone_no}</span>
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={350}>
                                    <HeaderCell>Role</HeaderCell>
                                    <Cell >
                                        {user => (
                                            user.roleid.map(roleId => (<div className={'role_' + roleId + ' role'} >{getRoleName(roleId)}</div>)) 
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={100}>
                                    <HeaderCell>Level</HeaderCell>
                                    <Cell >
                                        {user => (<span className="location">{getLevelName(user.levelid)}</span>)}
                                    </Cell>
                                </Column>

                                <Column width={200} fixed="right">
                                    <HeaderCell >Action</HeaderCell>
                                    <Cell >
                                        {(user, index) => (
                                            <div className="row" key={"Action" + index}>
                                                <ButtonToolbar>
                                                    <Button size="sm"  appearance="ghost" onClick={() => handleOpen(user)}>Modify Role</Button> 
                                                    <Button size="sm" appearance="default">Remove</Button>
                                                </ButtonToolbar>
                                            </div>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                        </div>
               
                    </div>

                    <div className='row  mb-5 mt-3'>
                        <div className='col-md-4'>
                            <span className="pagination-text">Show rows per page </span>
                            <select className='page ml-2'>
                                <option value="10">10</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='previous col-2'><img src={previousIcon} /></div>
                                <div className='current col-4 text-center pt-2 text-dark'><strong>1-10 </strong> of <span>12</span></div>
                                <div className='next col-2'><img src={nextIcon} /></div>
                            </div>
                        </div>
                    </div>

                    {/*Modal for profile details*/}
                    <UpdateRole updateRole={ updateUserRole} modalClose={handleClose} open={open} user={activeUser}></UpdateRole>

                </div>
            }
        </div>
    );
}
