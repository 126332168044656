import {axiosAuth, axiosNoAuth} from './customAxios';

const allMethod = {
	async GET(url, isAuth, body) {
		try {
			let data;
			if (isAuth) {
				data = await axiosAuth
                .get(url);	
			} else {
				data = await axiosNoAuth
                .get(url);
			}
            return data;
        } catch (err) {
            return err;
        }
	},
	async POST(url, isAuth, body) {
		try {
			let data;
			if (isAuth) {
				data = await axiosAuth
                .post(url, body);	
			} else {
				data = await axiosNoAuth
                .post(url, body);
			}
            return data;
        } catch (err) {
            return err;
        }
	},
	async PUT(url, isAuth, body) {
		try {
			let data;
			if (isAuth) {
				data = await axiosAuth
                .put(url, body);	
			} else {
				data = await axiosNoAuth
                .put(url, body);
			}
            return data;
        } catch (err) {
            return err;
        }
	},
	async DELETE(url, isAuth, body) {
		try {
			let data;
			if (isAuth) {
				data = await axiosAuth
                .delete(url);	
			} else {
				data = await axiosNoAuth
                .delete(url);
			}
            return data;
        } catch (err) {
            return err;
        }
	},
};

async function call(action, data) {
	let url = process.env.REACT_APP_apiPath + action.api;
	let bodyString = JSON.stringify(data);
	let isAuth = action.noAuth === true ? false : true;
	return await allMethod[action.method](url, isAuth, bodyString);
}

export default call;
